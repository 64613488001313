import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { iOutreachBankDetail } from '../model/outreach-bank-detail.model';
import { iOutreachBankHead } from '../model/outreach-bank-head.model';
import { OUTREACHBANKDETAIL } from '../mock/mock-outreach-bank-detail';
import { OUTREACHBANKHEAD } from '../mock/mock-outreach-bank-head';
import { MatTableDataSource } from '@angular/material/table';
import { OutreachBankService } from '../services/outreach-bank-service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-outreach-bank-account-report',
  templateUrl: './outreach-bank-account-report.component.html',
  styleUrl: './outreach-bank-account-report.component.css'
})
export class OutreachBankAccountReportComponent implements OnInit, AfterViewInit
{
  headerData: MatTableDataSource<iOutreachBankHead> = new MatTableDataSource<iOutreachBankHead>([]);
  detailData: MatTableDataSource<iOutreachBankDetail> = new MatTableDataSource<iOutreachBankDetail>([]);
  estimatedRemainingStateOnly: number;
  actualRemainingStateOnly: number;
  estimatedRemainingStateFederal: number;
  actualRemainingStateFederal: number;
  fiscalYear: string;
  noDataFound: boolean = false;
  noDetailData: boolean = false;
  organizationUuid: string;
  @ViewChild(MatSort) sort: MatSort;
  headerDisplayedColumns: string[] = 
  [
    'budget',
    'childNutrition',
    'cnSnap'
  ];
  detailDisplayedColumns: string[] = 
  [
    'referenceNumber',
    'county',
    'status',
    'stateOnly',
    'stateFederal',
    'vouchered',
    'voucherAMNT1',
    'voucherAMNT2'
  ];

  constructor
  (
    private outreachBankService: OutreachBankService,
    private route: ActivatedRoute
  )
  {
  }

  calcTotalStateOnlyActual(arrayData: iOutreachBankDetail[]): number 
  {
    return arrayData.map(outreach => Number(outreach.stateOnly)).reduce((acc, value) => acc + value, 0);
  }

  calcTotalStateFederalActual(arrayData: iOutreachBankDetail[]): number 
  {
    return arrayData.map(outreach => Number(outreach.stateFederal)).reduce((acc, value) => acc + value, 0);
  }

  calcTotalStateOnlyEst(arrayData: iOutreachBankDetail[]): number 
  {
    return arrayData.map(outreach => Number(outreach.creditStateEst)).reduce((acc, value) => acc + value, 0);
  }

  calcTotalStateFederalEst(arrayData: iOutreachBankDetail[]): number 
  {
    return arrayData.map(outreach => Number(outreach.creditFederalEst)).reduce((acc, value) => acc + value, 0);
  }

  filterVoucheredAndApproved(detailData: iOutreachBankDetail[]): iOutreachBankDetail[]
  {
    return detailData.filter(row => row.statusId === 'APPROVED' && row.vouchered === 'Y');
  }

  filterApprovedNotVouchered(detailData: iOutreachBankDetail[]): iOutreachBankDetail[]
  {
    return detailData.filter(row => row.statusId === 'APPROVED' && row.vouchered === 'N');
  }

  // calculations for footer table
  setEstRemainingStateOnly(headerData: iOutreachBankHead[], detailData: iOutreachBankDetail[]): number
  {
    const totalStateOnly = headerData[0].debitState;
    const estStateOnly = this.calcTotalStateOnlyEst(detailData);
    console.log(totalStateOnly, estStateOnly)
    return totalStateOnly - estStateOnly;
  }

  setEstRemainingStateFederal(headerData: iOutreachBankHead[], detailData: iOutreachBankDetail[]): number
  {
    const totalStateFederal = headerData[0].debitFederal;
    const estStateFederal = this.calcTotalStateFederalEst(detailData);
    return totalStateFederal - estStateFederal;
  }

  setActualRemainingStateOnly(headerData: iOutreachBankHead[], detailData: iOutreachBankDetail[]): number
  {
    const approvedAndVouchered = this.filterVoucheredAndApproved(detailData);
    const totalStateOnly = headerData[0].debitState;
    const estStateOnly = this.calcTotalStateOnlyActual(approvedAndVouchered);
    return totalStateOnly - estStateOnly;
  }

  setActualRemainingStateFederal(headerData: iOutreachBankHead[], detailData: iOutreachBankDetail[]): number
  {
    const approvedAndVouchered = this.filterVoucheredAndApproved(detailData);
    const totalStateFederal = headerData[0].debitFederal;
    const estStateFederal = this.calcTotalStateFederalActual(approvedAndVouchered);
    return totalStateFederal - estStateFederal;
  }

  fetchData(organizationUuid: string, fiscalYear: string) 
  {
    forkJoin({
      rawHeaderData: this.outreachBankService.getbankHeadByOrgAndYear(organizationUuid, fiscalYear),
      rawDetailData: this.outreachBankService.getbankDetailByOrgAndYear(organizationUuid, fiscalYear)
    }).subscribe({
      next: ({ rawHeaderData, rawDetailData }) => {
        console.log('Header data:', rawHeaderData);
        console.log('Detail data:', rawDetailData);
  
        if (rawHeaderData && rawHeaderData.length > 0 && rawDetailData && rawDetailData.length > 0) {
          this.headerData.data = rawHeaderData;
          this.detailData.data = rawDetailData;
  
          this.estimatedRemainingStateOnly = this.setEstRemainingStateOnly(rawHeaderData, rawDetailData);
          this.actualRemainingStateOnly = this.setActualRemainingStateOnly(rawHeaderData, rawDetailData);
          this.estimatedRemainingStateFederal = this.setEstRemainingStateFederal(rawHeaderData, rawDetailData);
          this.actualRemainingStateFederal = this.setActualRemainingStateFederal(rawHeaderData, rawDetailData);
        }else if (rawHeaderData.length > 0  && rawDetailData.length < 1)
        {
          this.headerData.data = rawHeaderData
          this.noDetailData = true

          this.estimatedRemainingStateOnly = this.headerData.data[0].debitState
          this.actualRemainingStateOnly = this.headerData.data[0].debitState
          this.estimatedRemainingStateFederal = this.headerData.data[0].debitFederal
          this.actualRemainingStateFederal = this.headerData.data[0].debitFederal
        } 
        else 
        {
          console.log('XXX')
          this.noDataFound = true; 
        }
      },
      error: error => {
        console.error('Error fetching data:', error);
      }
    });
  }
  
  
  ngOnInit() 
  {
    this.route.queryParams.subscribe(params => {
      this.fiscalYear = params['fiscalYearId'];
      this.organizationUuid = params['organizationUuid'];
    
      this.fetchData(this.organizationUuid, this.fiscalYear);
    });
  }
  
  ngAfterViewInit() {
    this.detailData.sort = this.sort;
  }
}
