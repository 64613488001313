import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BudgetService } from '../budget.service';
import { SnackRouteService } from '../snack-route.service';

@Component({
    selector: 'app-confirm-create-elements',
    templateUrl: 'confirm-create-elements.component.html',
    styleUrls: ['confirm-create-dialog.component.css']
  })
  export class ConfirmCreateElements {
    dialogName: string
    dialogMessage: string
    submissionUuid: string
    confirmCheck: boolean
    sysArea: string
    budgetId: string
  
    constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<ConfirmCreateElements>,
      public budgetService: BudgetService,
      public snackService: SnackRouteService,
      @Inject(MAT_DIALOG_DATA) public data: any) 
      {
          this.dialogName = data.dialogName,
          this.dialogMessage = data.dialogMessage,
          this.submissionUuid = data.submissionUuid,
          this.sysArea = data.sysArea,
          this.budgetId = data.budgetId
      }
  
    closeDialog() {
      this.dialog.closeAll()
    }
  
    confirmDialog() {
        if(this.dialogName == 'budget amendment') {
          this.budgetService.amendBudget(this.submissionUuid).subscribe(response => {
              if(response.success === true) {
                  this.snackService.snack('Budget amendment has been successfully created!')
                  this.closeDialog()
              }
          })
        }

        if(this.dialogName == 'voucher') {
          console.log(this.budgetId, this.submissionUuid)
          this.budgetService.createVoucher(this.budgetId, this.submissionUuid).subscribe(response =>  {
            if(response.success === true) {
              this.snackService.snack('Voucher has been changed to draft state.')
              this.closeDialog()
            }
          })
        }
    }
  }