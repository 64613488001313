import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import {
    faHome, faSearch, faBezierCurve, faUser, faSignOutAlt, faCaretDown, faCaretUp, faUsers, faCogs,
    faTasks, faFileAlt, faKey, faQuestion, faFileInvoice, faTachometerAlt, faFileInvoiceDollar,
    faBookReader, faChartBar, faFile, faSearchPlus, faSearchMinus, faFileMedical, faFolderMinus,
    faFileImport, faFilePdf, faFileDownload, fas, faBuilding, faLongArrowAltRight, faMoneyBillWave,
    faBullseye
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';
import { SystemService } from '../system.service';
import { OutreachService } from '../outreach.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
    fas = fas
    faHome = faHome;
    faSearch = faSearch;
    faBezierCurve = faBezierCurve;
    faSignOutAlt = faSignOutAlt
    faUser = faUser;
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp
    userFullName;
    faUsers = faUsers
    faCogs = faCogs
    faTasks = faTasks
    faFileAlt = faFileAlt
    faKey = faKey
    faQuestion = faQuestion
    faFileInvoice = faFileInvoice
    faTachometerAlt = faTachometerAlt
    faFileInvoiceDollar = faFileInvoiceDollar
    faBookReader = faBookReader
    faChartBar = faChartBar
    faFile = faFile
    faSearchPlus = faSearchPlus
    faSearchMinus = faSearchMinus
    faFileMedical = faFileMedical
    faFolderMinus = faFolderMinus
    faFileImport = faFileImport
    faFilePdf = faFilePdf
    faFileDownload = faFileDownload
    faBuilding = faBuilding
    faLongArrowAltRight = faLongArrowAltRight
    faMoneyBillWave = faMoneyBillWave
    faBullseye = faBullseye
    
    features
    initialized
    photo
    userOrganizationType
    @ViewChild("profileImageElement", { read: ElementRef }) profileImageElement: ElementRef;
    @ViewChild("profileImageElement2", { read: ElementRef }) profileImageElement2: ElementRef;

    leftMenuItems
    rightMenuItems

    isCurrentLink(link) {
        return this.router.url == link || this.router.url.startsWith(link + "?")
    }

    isCurrentSubmenu(menuItem) {
        if (this.isCurrentLink(menuItem.link)) {
            return true
        }
        //console.log("DEBUG", menuItem.additionalRoutes)
        if (menuItem.additionalRoutes) {
            for (var i = 0; i < menuItem.additionalRoutes.length; ++i) {
                if (this.isCurrentLink(menuItem.additionalRoutes[i])) {
                    return true
                }
            }
        }
    }

    isCurrentMenuItem(leftMenuItem) {
        if (this.isCurrentSubmenu(leftMenuItem)) {
            return true;
        }
        if (leftMenuItem.submenu) {
            for (var i = 0; i < leftMenuItem.submenu.length; ++i) {
                if (this.isCurrentSubmenu(leftMenuItem.submenu[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    constructor(
        private router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private systemService: SystemService,
        private activatedRoute: ActivatedRoute,
        private outreachService: OutreachService,
    ) { }

    get authService() {
        return this._authService
    }

    get userService() {
        return this._userService
    }

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    getUserFirstLetter() {
        if (this.userFullName && this.userFullName.trim()) {
            return this.userFullName.trim().substring(0, 1).toUpperCase();
        }
        if (this.authService.username && this.authService.username.trim()) {
            return this.authService.username.trim().substring(0, 1).toUpperCase();
        }
        return " "
    }

    loadImageInElement(img, elem) {
        var newHeight = 32;
        var newWidth = this.getImageWidth(img.width, img.height, newHeight);
        img.width = newWidth;
        img.height = newHeight;
        if (elem) {
            while (elem.nativeElement.firstChild) {
                elem.nativeElement.removeChild(elem.nativeElement.firstChild);
            }
            elem.nativeElement.appendChild(img);
        }
    }

    loadLetterInElement(elem) {
        var container = document.createElement('div');
        container.classList.add("letter-container")
        container.style.display = "inline-block"
        container.style.position = "relative"
        container.style.textAlign = "center"

        var letterBack = document.createElement('div');
        letterBack.classList.add("letter-back")
        letterBack.innerHTML = "&nbsp;"

        var letter = document.createElement('div');
        letter.classList.add("letter")
        letter.innerHTML = this.getUserFirstLetter();

        container.appendChild(letterBack)
        container.appendChild(letter)

        if (elem) {
            while (elem.nativeElement.firstChild) {
                elem.nativeElement.removeChild(elem.nativeElement.firstChild);
            }
            elem.nativeElement.appendChild(container);
        }
    }

    loadProfileImage() {
        if (this.photo) {
            this.systemService.getProfileFile(this.photo).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img')
                    img.style.verticalAlign = "top";
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        this.loadImageInElement(img, this.profileImageElement)
                    }.bind(this);

                    var img2 = document.createElement('img')
                    img2.style.verticalAlign = "top";
                    (<any>img2.src) = reader.result;
                    img2.onload = function () {
                        this.loadImageInElement(img2, this.profileImageElement2)
                    }.bind(this);
                }
            });
        } else {
            this.loadLetterInElement(this.profileImageElement)
            this.loadLetterInElement(this.profileImageElement2)
        }
    }

    photoInitted = false;
    viewInitted = false;

    doLoadProfileImage() {
        setTimeout(() => {
            this.userService.profileImageId.next(this.photo);
            this.userService.profileImageId.subscribe((photoId) => {
                this.photo = photoId;
                this.loadProfileImage();
            });
        }, 0)
    }

    getMenuItemsPromise() {
        return new Promise((resolve, reject) => {
            this.systemService.getMenuItems().subscribe((response) => {
                //console.log("menu items", response)

                if (response.success) {
                    this.leftMenuItems = []
                    this.rightMenuItems = []

                    var lastItem = null
                    var lastSubItem = null
                    for (var i = 0; i < response.result.length; ++i) {
                        if (response.result[i].nav_id == "EMAIL") {
                            continue
                        }
                        if (response.result[i].nav_id == "NEW" && !this.canUserCreateOutreachCampaign) {
                            continue
                        }
                        if (response.result[i].sub_id == "DOCUMENT_MASTER" && this.userOrganizationType == "SUB") {
                            continue
                        }
                        if (response.result[i].sub_id == "UTILITY_REPORT" && this.userOrganizationType == "SUB") {
                            continue
                        }
                        if (response.result[i].sub_id == "SITE_LOCATIONS" && this.userOrganizationType == "SUB") {
                            continue
                        }
                        if (response.result[i].sub_id == "QUESTIONS" && this.userOrganizationType == "SUB") {
                            continue;
                        }
                        if (response.result[i].sub_id == "SUBMISSION_SCHEDULE" && this.userOrganizationType == "SUB") {
                            continue;
                        }
                        if (lastItem == null || lastItem.id != response.result[i].nav_id) {
                            lastItem = {
                                id: response.result[i].nav_id,
                                title: response.result[i].nav_name,
                                link: response.result[i].nav_link,
                                icon: this.fas[response.result[i].nav_icon],
                                submenu: [],
                            }
                            if (response.result[i].alignment == "left") {
                                this.leftMenuItems.push(lastItem)
                            } else {
                                this.rightMenuItems.push(lastItem)
                            }

                        }
                        if (response.result[i].sub_id) {
                            if (lastSubItem == null || response.result[i].sub_id != response.result[i - 1].sub_id) {
                                lastSubItem = {
                                    title: response.result[i].sub_name,
                                    link: response.result[i].sub_link,
                                    icon: this.fas[response.result[i].sub_icon],
                                    additionalRoutes: [],
                                }
                                lastItem.submenu.push(lastSubItem)
                            }
                            if (response.result[i].additional_link) {
                                lastSubItem.additionalRoutes.push(response.result[i].additional_link)
                            }
                        }

                    }
                    //console.log("menu", this.leftMenuItems, this.rightMenuItems)
                }
                resolve(null)
            })
        })
    }

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                //console.log("org type", response)
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    userOrganizations
    getUserOrganizations() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizations().subscribe((response) => {
                //console.log("orgs", response)
                if (response.success) {
                    this.userOrganizations = response.result
                } else {
                    console.log("error while getting user organizations", response)
                }
                resolve(null)
            })
        })
    }

    canUserCreateOutreachCampaign
    getCanUserCreateOutreachCampaignPromise() {
        return new Promise((resolve, reject) => {
            this.outreachService.getOutreachCanUserCreateCampaign().subscribe((response) => {
                //console.log("can create outreach campaign", response)
                if (response.success) {
                    this.canUserCreateOutreachCampaign = response.result
                } else {
                    console.log("error while getting can user create outreach campaign", response)
                }
                resolve(null)
            })
        })
    }

    activatedRouteSubscription
    currentRoute
    routeParams
    getCurrentRouteInfo() {
        this.activatedRouteSubscription = this.activatedRoute.url.subscribe((url) => {
            //console.log("activated route url", url)
        })
    }

    currentUserOrganizationLocalStorageItemName = "hsny_currentUserOrganization"
    setUserCurrentOrganization(value) {
        if (value == null) {
            localStorage.removeItem(this.currentUserOrganizationLocalStorageItemName)
            return
        }
        localStorage.setItem(this.currentUserOrganizationLocalStorageItemName, value)
        window.location.reload()

        //console.log("navigate to", this.router.url)

        // this.router.navigate(['/'], { skipLocationChange: false }).then(() => {
        //     console.log("routing to", this.router.url)
        //     this.router.navigate([this.router.url])
        // });
    }

    get currentUserOrganization() {
        return localStorage.getItem(this.currentUserOrganizationLocalStorageItemName)
    }

    // ngOnDestroy() {
    //     this.activatedRouteSubscription.unsubscribe()
    // }

    environmentType
    getEnvironmentType() {
        return new Promise((resolve, reject) => {
            this.systemService.getEnvironmentType().subscribe((response) => {
                if (response.success) {
                    this.environmentType = response.result
                } else {
                    console.log("error while gettingenvironment type", response)
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        //console.log("menu init")
        Promise.all([
            this.getCurrentRouteInfo(),
            this.getUserOrganizations(),
            this.getUserOrganizationType(),
            this.getCanUserCreateOutreachCampaignPromise(),
            this.getEnvironmentType(),
        ]).then(() => {

            var promises = []

            promises.push(this.getMenuItemsPromise())

            Promise.all(promises).then(() => {
                this.userService.getUser(this.authService.userId).subscribe(response => {
                    if (response.success) {
                        if (response.result && response.result.length > 0) {
                            this.userFullName = response.result[0].full_name;
                            this.photo = response.result[0].photo_id
                        }
                    }

                    this.initialized = true;
                    this.doLoadProfileImage()
                })
            })
        })
    }

    nav(page) {
        if (this.router.url == '/' + page) {
            return;
        }
        this.router.navigate(['/' + page]);
    }

    curPage(page) {
        return this.router.url == '/' + page;
    }

    toggleUserDropdown() {
        var display = document.getElementById('userDropdown').style.display;
        if (display == 'none') {
            document.getElementById('userDropdown').style.display = 'block';
        } else {
            document.getElementById('userDropdown').style.display = 'none';
        }
    }

    displayCaret(direction) {
        var dropdownElem = document.getElementById('userDropdown');
        if (direction == "up") {
            return dropdownElem.style.display == "block";
        }
        if (direction == "down") {
            return dropdownElem.style.display == "none";
        }
    }
}
