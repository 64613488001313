<ng-container *ngIf="isLoading">
  <h3>Loading...</h3>
</ng-container>

<app-agency-main-target 
*ngIf="submissionUuid && submissionUuid != '0'" 
isODTA='true' 
[submissionUuid]="submissionUuid"
></app-agency-main-target>

<ng-container *ngIf="!isLoading && groupDataArray.length">
  <ng-container *ngFor="let group of groupDataArray">
    <h3 class="header">{{ group }}</h3>
    <table mat-table [dataSource]="groupedData[group]">
      <ng-container matColumnDef="question_name">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let question" class="description-box">{{ question.question_name }}</td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let question" class="value-box">{{ question.value | number }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['question_name', 'value']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['question_name', 'value']" class="mat-row"></tr>
    </table>
    <!-- <div class="total-container">
      <h6>Totals will go here</h6>
    </div> -->
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoading && groupDataArray.length === 0">
  <h3>No results found matching the selected criteria</h3>
</ng-container>

