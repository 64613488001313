<div *ngIf="initialized && authService.hasValidAuthenticationToken()" class="container-fluid px-0">
    <nav class="navbar navbar-expand-lg navbar-light px-0">
        <a class="navbar-brand" href="#"><img src="assets/logo.png" height="37" width="153" class="ml-2"
                data-cy="navbar_brand_logo" /></a>
        <span>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <span class="d-inline d-lg-none px-2 dropdown">
                <a data-toggle="dropdown" href="#mobile-user-dropdown" id="navbarDropdown2" #profileImageElement2>
                </a>
                <div class="dropdown-menu dropdown-menu-right" id="mobileUserDropdown"
                    aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" style="text-decoration: none;" routerLink="/user"
                        [queryParams]="{ userUuid: this.authService.userId, viewMode: true }">
                        <fa-icon [icon]="faUser" class="mr-2"></fa-icon> My Profile
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item pointer" (click)="authService.doLogout()">
                        <fa-icon [icon]="faSignOutAlt" class="mr-2"></fa-icon> Logout
                    </a>
                </div>
            </span>
        </span>
        <div class="collapse navbar-collapse ml-2" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li *ngFor="let menuItem of leftMenuItems" class="mr-3">
                    <a [routerLink]="menuItem.link" class="main-item d-none d-lg-inline" style="white-space: nowrap;"
                        [ngClass]="{'bold': isCurrentMenuItem(menuItem)}">
                        <fa-icon class="main-item-icon mr-1" [icon]="menuItem.icon"></fa-icon>{{menuItem.title}}
                    </a>
                    <span class="d-inline d-lg-none dropdown">
                        <a data-toggle="collapse" [attr.data-target]="'#menuDropdown' + menuItem.id"
                            data-parent="#navbarSupportedContent" [attr.aria-controls]="'menuDropdown' + menuItem.id"
                            aria-expanded="false" aria-label="Toggle navigation" class="main-item"
                            style="white-space: nowrap;" href="#" [ngClass]="{'bold': isCurrentMenuItem(menuItem)}"
                            (click)="nav(menuItem.link)">
                            <fa-icon class="main-item-icon" [icon]="menuItem.icon"></fa-icon> {{menuItem.title}}
                        </a>
                        <div class="collapse navbar-collapse multi-collapse" id="menuDropdown{{menuItem.id}}">
                            <ul class="navbar-nav mr-auto">
                                <li *ngFor="let submenuItem of menuItem.submenu" (click)="nav(submenuItem.link)"
                                    class="pointer thick-border px-2 submenu-row-expandable"
                                    [ngClass]="{'selectedSubmenu-expandable': isCurrentSubmenu(submenuItem), 'unselectedSubmenu': !isCurrentSubmenu(submenuItem)}">
                                    <fa-icon [icon]="submenuItem.icon"></fa-icon> {{submenuItem.title}}
                                </li>
                            </ul>
                        </div>
                    </span>
                </li>
            </ul>
            <a class="d-none d-lg-inline" data-toggle="dropdown" href="#fullscreen-user-dropdown" #profileImageElement>
            </a>
            <span class="d-none d-lg-inline nav-item dropdown" id="fullscreen-user-dropdown">
                <a class="nav-link dropdown-toggle px-0 ml-2 mr-3 main-item" data-cy="navbarDropdown"
                    href="#fullscreen-user-dropdown" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span style="vertical-align: top;">
                        Hi<span *ngIf="userFullName">, <span style="white-space: nowrap;">{{userFullName}}</span>
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" style="text-decoration: none;" routerLink="/user"
                        [queryParams]="{ userUuid: this.authService.userId, viewMode: true }">
                        <fa-icon [icon]="faUser" class="mr-2"></fa-icon> My Profile
                    </a>
                    <a *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'" class="dropdown-item"
                        style="text-decoration: none;" routerLink="/budget-appropriation" [queryParams]="{ uuid: '4' }">
                        <fa-icon [icon]="faMoneyBillWave" class="mr-2"></fa-icon> 2021 Budget
                    </a>
                    <div *ngIf="userOrganizations && userOrganizations.length > 1" class="dropdown-divider"></div>
                    <span *ngIf="userOrganizations && userOrganizations.length > 1">
                        <span *ngFor="let organization of userOrganizations">
                            <a *ngIf="organization.uuid != currentUserOrganization" class="dropdown-item pointer"
                                (click)="setUserCurrentOrganization(organization.uuid)">
                                <fa-icon [icon]="faBuilding" class="mr-2"></fa-icon> {{organization.name}}
                            </a>
                            <span *ngIf="organization.uuid == currentUserOrganization" class="dropdown-item">
                                <fa-icon [icon]="faLongArrowAltRight" class="mr-2"></fa-icon> {{organization.name}}
                            </span>
                        </span>
                    </span>
                    <div class="dropdown-divider"></div>
                    <a *ngIf="authService.userId == 'uuidAdmin'" class="dropdown-item pointer" (click)="nav('crud')">
                        <fa-icon [icon]="faCogs" class="mr-2"></fa-icon> Database Administration
                    </a>
                    <a *ngIf="authService.userId == 'uuidAdmin'" class="dropdown-item pointer" (click)="nav('crud-child-tree')">
                        <fa-icon [icon]="faCogs" class="mr-2"></fa-icon> Data Joiner
                    </a>
                    <a *ngIf="authService.userId == 'uuidAdmin'" class="dropdown-item pointer" (click)="nav('crud-ddl')">
                        <fa-icon [icon]="faCogs" class="mr-2"></fa-icon> DDL
                    </a>
                    <a *ngIf="authService.userId == 'uuidAdmin'" class="dropdown-item pointer" (click)="nav('wic22-results')">
                        <fa-icon [icon]="faCogs" class="mr-2"></fa-icon> WIC Results
                    </a>
                    <div *ngIf="authService.userId == 'uuidAdmin'" class="dropdown-divider"></div>
                    <a class="dropdown-item pointer" (click)="authService.doLogout()">
                        <fa-icon [icon]="faSignOutAlt" class="mr-2"></fa-icon> Logout
                    </a>
                </div>
            </span>
            <ul class="navbar-nav">
                <li *ngFor="let menuItem of rightMenuItems" class="mr-3">
                    <a [routerLink]="menuItem.link" class="main-item" style="white-space: nowrap;"
                        [ngClass]="{'bold': isCurrentMenuItem(menuItem)}">
                        <fa-icon class="main-item-icon" [icon]="menuItem.icon"></fa-icon> {{menuItem.title}}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</div>

<div *ngIf="initialized && authService.hasValidAuthenticationToken()" class="container-fluid d-none d-lg-block">
    <div class="row mt-1 submenu-row">
        <span *ngFor="let leftMenuItem of leftMenuItems">
            <span *ngIf="isCurrentMenuItem(leftMenuItem)">
                <span *ngFor="let submenuItem of leftMenuItem.submenu">
                    <a *ngIf="submenuItem.link.startsWith('/')" [routerLink]="submenuItem.link">
                        <div class="pointer thick-border py-3 px-2"
                            [ngClass]="{'selectedSubmenu': isCurrentSubmenu(submenuItem), 'unselectedSubmenu': !isCurrentSubmenu(submenuItem)}"
                            style="height: 4em; display: inline-block;">
                            <fa-icon [icon]="submenuItem.icon" class="mr-1"></fa-icon>{{submenuItem.title}}
                        </div>
                    </a>
                    <a *ngIf="!submenuItem.link.startsWith('/')" [href]="submenuItem.link">
                        <div class="pointer thick-border py-3 px-2"
                            [ngClass]="{'selectedSubmenu': isCurrentSubmenu(submenuItem), 'unselectedSubmenu': !isCurrentSubmenu(submenuItem)}"
                            style="height: 4em; display: inline-block;">
                            <fa-icon [icon]="submenuItem.icon" class="mr-1"></fa-icon>{{submenuItem.title}}
                        </div>
                    </a>
                </span>
            </span>
        </span>
        <span *ngFor="let rightMenuItem of rightMenuItems">
            <span *ngIf="isCurrentMenuItem(rightMenuItem)">
                <span *ngFor="let submenuItem of rightMenuItem.submenu">
                    <a *ngIf="submenuItem.link.startsWith('/')" [routerLink]="submenuItem.link">
                        <div class="pointer thick-border py-3 px-2"
                            [ngClass]="{'selectedSubmenu': isCurrentSubmenu(submenuItem), 'unselectedSubmenu': !isCurrentSubmenu(submenuItem)}"
                            style="height: 4em; display: inline-block;">
                            <fa-icon [icon]="submenuItem.icon" class="mr-1"></fa-icon>{{submenuItem.title}}
                        </div>
                    </a>
                    <a *ngIf="!submenuItem.link.startsWith('/')" [href]="submenuItem.link" target="_blank">
                        <div class="pointer thick-border py-3 px-2"
                            [ngClass]="{'selectedSubmenu': isCurrentSubmenu(submenuItem), 'unselectedSubmenu': !isCurrentSubmenu(submenuItem)}"
                            style="height: 4em; display: inline-block;">
                            <fa-icon [icon]="submenuItem.icon" class="mr-1"></fa-icon>{{submenuItem.title}}
                        </div>
                    </a>
                </span>
            </span>
        </span>
    </div>
</div>