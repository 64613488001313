import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import {UntypedFormControl, Validators, FormGroup} from '@angular/forms';
import { ProgramService } from '../program.service';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-program-data-collection',
    templateUrl: './program-data-collection.component.html',
    styleUrls: ['./program-data-collection.component.css']
})
export class ProgramDataCollectionComponent implements OnInit {    
    faDatabase = faDatabase

    // Declare new form control instances
    program = new UntypedFormControl('', Validators.required)
    year = new UntypedFormControl('', Validators.required)
    agency = new UntypedFormControl('')
    quarter = new UntypedFormControl({value: '', disabled: true})
    county = new UntypedFormControl({value: '', disabled: true})

    // Declare global string type variables
    programId: string
    fiscalYearId: string
    organizationUuid: string
    quarterUuid: string
    jurisdictionId: string


    // Declare drop down any type globals will be an array/list of dictionaries.
    programDropDown: any
    yearDropDown: any
    agencyDropDown: any
    quarterDropDown: any
    countyDropDown: any

    programReportData: any[] = [];

    // Session claim variables
    claims: any

    constructor(
        private programService: ProgramService,
        private sessionService: SessionService
    ) { }

    groupedData: {[key: string]: { question_name: string, value: string }[] } = {};

    groupDataArray: any[] = [];
  
    tableData: any

    reportDisplay: boolean

    submissionUuid: string = '0';

    // Drop down functions to handle service calls and set drop down data.
    // getProgramDropDown() {
    //     this.programService.programDropDown().subscribe(response => {
    //         response.result.splice(0, 1)
    //         this.programDropDown = response.result
    //         //console.log(this.programDropDown)
    //     })
    // }

    getYearDropDown(programId: string) {
        this.programService.yearDropDown(programId).subscribe(response => {
            this.yearDropDown = response.result
        })
    }

    getAgencyDropDown(programId: string, fiscalYearId: string) {
        this.programService.agencyDropDown(programId, fiscalYearId).subscribe(response => {
            this.agencyDropDown = response.result
            //console.log(this.agencyDropDown)
        })
    }

    getQuarterDropDown(programId: string, fiscalYearId: string, organizationUuid: string) {
        this.programService.quarterDropDown(programId, fiscalYearId, organizationUuid).subscribe(response => {
            this.quarterDropDown = response.result
            //console.log(this.quarterDropDown)
        })
    }

    getCountyDropDown(organizationUuid: string, fiscalYearId: string) {
        this.programService.countyDropDown(organizationUuid, fiscalYearId).subscribe(response => {
            this.countyDropDown = response.result
        })
    }

    generateReportTableData() {
        this.programService.getProgramReport(this.programId, this.fiscalYearId, this.organizationUuid, this.quarterUuid, this.jurisdictionId).subscribe({
          next: (data) => {
            this.programReportData = data.result;
    
            console.log(this.programId, this.fiscalYearId, this.organizationUuid, this.quarterUuid, this.jurisdictionId)
      
            this.programReportData.sort((a, b) => {
              if (a.group_number === b.group_number) {
                return a.question_number - b.question_number;
              }
              return a.group_number - b.group_number;
            });
      
            this.groupedData = {};
            this.groupDataArray = [];
      
            this.programReportData.forEach((item) => {
              let groupName = item.group_name;
      
              if (!this.groupedData[groupName]) {
                this.groupedData[groupName] = [];
              }
      
              const existingQuestion = this.groupedData[groupName].find(
                (question) => question.question_name === item.question_name
              );
      
              if (existingQuestion) {
                existingQuestion.value = item.value; // Update the value instead of adding to it
              } else {
                const question = {
                  question_name: item.question_name,
                  value: item.value,
                };
      
                this.groupedData[groupName].push(question);
              }
      
              if (!this.groupDataArray.includes(groupName)) {
                this.groupDataArray.push(groupName);
              }
            });
      
            this.tableData = this.programService.convertEntityToModel(data.result);
          },
          error: (err) => {
            console.log(err);
          },
        });
        this.reportDisplay = true
        if(this.jurisdictionId)
        {
            const matchingId = this.countyDropDown.find(item => item.id === this.jurisdictionId)
            if(matchingId)
            {
                this.submissionUuid = matchingId.submission_uuid
            } 
        }
        else
        {
            this.submissionUuid = '0';
        }
        // console.log(this.submissionUuid)
    }

    onYearChange(result: any, selectedProgram: string, selectedYear: string)
    {
        if(result) 
        {
            selectedYear = result
            this.getAgencyDropDown(selectedProgram, selectedYear)
        }
    }

    // Material error message handling function
    getErrorMessage() {
        if(this.program.hasError('required')) {
            return 'You must enter a program'
        }

        if(this.year.hasError('required')) {
            return 'You must enter a year'
        }
    }

    // NG initializer
    ngOnInit() {
        // set claims                                                                                                                                 
        this.claims = this.sessionService.getClaims()
        this.fiscalYearId = this.sessionService.getFiscalYear()

        // if org type is prime
        if(this.claims.organization_type == 'PRIME') {
            this.programId = 'NOEP'
            // this.fiscalYearId = '2023'
            this.organizationUuid = ''
            this.quarterUuid = ''
            this.jurisdictionId = ''


            this.program.setValue(this.programId)
            this.year.setValue(this.fiscalYearId)


            //this.getProgramDropDown()            
            this.getYearDropDown(this.programId)
            this.getAgencyDropDown(this.programId, this.fiscalYearId)

            // this.program.valueChanges.subscribe(result => {
            //     if(result) 
            //     {
            //         this.programId = result
            //         this.getYearDropDown(this.programId)
            //     }
            // })

            this.year.valueChanges.subscribe(result => {
                this.fiscalYearId = result
                this.onYearChange(result, this.programId, this.fiscalYearId)
                this.organizationUuid = '';
                this.quarterUuid = '';
                this.jurisdictionId = '';
                this.agency.setValue(null, { emitEvent: false });
                this.county.setValue(null, { emitEvent: false });
                this.quarter.setValue(null, { emitEvent: false });
                this.quarter.disable();
                this.county.disable();
            })

            this.agency.valueChanges.subscribe((result) => {
                if (result)
                {
                    this.quarter.enable();
                    this.organizationUuid = result
                    this.getQuarterDropDown(this.programId, this.fiscalYearId, this.organizationUuid)
                    this.quarter.setValue('0')
                    this.county.setValue('0')
                }
                else
                {
                    this.organizationUuid = '';
                    this.quarterUuid = '';
                    this.jurisdictionId = '';
                    this.quarter.disable();
                    this.county.disable();
                }
                // this.county.setValue(null);
                // this.quarter.setValue(null);
            })

            this.quarter.valueChanges.subscribe((result) => {
                if (result)
                {
                    this.quarterUuid = result
                    this.county.enable();
                    this.getCountyDropDown(this.organizationUuid, this.fiscalYearId)
                    this.county.setValue('0')
                }
                else
                {
                    this.quarterUuid = '';
                    this.jurisdictionId = '';
                    this.county.disable();
                }
                // this.county.setValue(null);
            })

            this.county.valueChanges.subscribe((result) => {
                if (result) 
                {
                    this.jurisdictionId = result
                }
                else
                {
                    this.jurisdictionId = '';
                }
            })
        }  
    }
}
