<div class="row page-title">
    <div class="col grey-header">
        Trend Report
    </div>
</div>

<div class="header">
    <div class="title">{{ organizationName }}</div>
    
    <div class="details">
        <div class="info-left">
            <div class="info-group">
                <div class="info-label">Fiscal Year:</div>
                <div class="info-value">{{ fiscalYear }}</div>
            </div>

            <div class="info-group">
                <div class="info-label">Budget</div>
                <div class="info-label">State Only:</div>
                <div class="info-value">$ {{ stateAmount | number:'1.0-0' }}</div>
                <div class="info-label">State/Fed:</div>
                <div class="info-value">$ {{ federalAmount | number:'1.0-0' }}</div>
            </div>
        </div>

        <div class="table-switch-container">
            <p-selectButton 
                [options]="tableOptions" 
                [(ngModel)]="defaultTable" 
                optionLabel="label" 
                optionValue="value" />
        </div>
    </div> 
</div>


<div class="trend-table">
    <app-trend-report-monthly-table
        *ngIf="defaultTable === 'monthly' && trendReportDetails?.length > 0"
        [trendReportDetails]="trendReportDetails">
    </app-trend-report-monthly-table>

    <app-trend-report-quarterly-table
        *ngIf="defaultTable === 'quarterly' && trendReportQtr?.length > 0"
        [trendReportQtr]="trendReportQtr">
    </app-trend-report-quarterly-table>
</div>

