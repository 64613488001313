import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './header.interceptor';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { GlobalErrorHandler } from './global-error-handler';
import { NumberFormatPipe } from './number-format.pipe';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { LogoHeaderComponent } from './logo-header/logo-header.component';
import { DashboardComponent, TempDashboardDialog } from './dashboard/dashboard.component';
import { OverdueItemsComponent } from './overdue-items/overdue-items.component';
import { SearchComponent } from './search/search.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { OutreachApprovalComponent } from './outreach-approval/outreach-approval.component';
import { BudgetAmendmentComponent } from './budget-amendment/budget-amendment.component';
import { DocumentForReviewComponent } from './document-for-review/document-for-review.component';
import { BudgetCalculatorComponent } from './budget-calculator/budget-calculator.component';
import { ProgramDataCollectionComponent } from './program-data-collection/program-data-collection.component';
import { OverdueTransactionsComponent } from './overdue-transactions/overdue-transactions.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageOrganizationsComponent } from './manage-organizations/manage-organizations.component';
import { ManageSiteLocationsComponent } from './manage-site-locations/manage-site-locations.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { FaqsComponent } from './faqs/faqs.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AgencyComponent } from './agency/agency.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputImageComponent } from './input-image/input-image.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputEnumComponent } from './input-enum/input-enum.component';
import { UserComponent } from './user/user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DocumentMasterListingComponent } from './document-master-listing/document-master-listing.component';
import { DocumentMasterComponent } from './document-master/document-master.component';
import { InputTextAndEnumComponent } from './input-text-and-enum/input-text-and-enum.component';
import { SortIndicatorComponent } from './sort-indicator/sort-indicator.component';
import { InputDocumentComponent } from './input-document/input-document.component';
import { FileSizePipe } from './file-size.pipe';
import { SubmissionReviewComponent } from './submission-review/submission-review.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { SubmissionAttachmentsComponent } from './submission-attachments/submission-attachments.component';
import { ConfirmationReviewComponent } from './confirmation-review/confirmation-review.component';
import { ManageQuestionsComponent } from './manage-questions/manage-questions.component';
import { EditQuestionGroupComponent } from './edit-question-group/edit-question-group.component';
import { EditQuestionComponent, EditQuestionDeleteConfirmationDialog} from './edit-question/edit-question.component';
import { AnswerDialog } from './edit-question/answer-dialog/answer-dialog.component';
import { EditQuestionStepComponent } from './edit-question-step/edit-question-step.component';
import { SubmissionQuestionStepsComponent } from './submission-question-steps/submission-question-steps.component';
import { InputSplitButtonComponent } from './input-split-button/input-split-button.component';
import { OutreachCampaignRequestComponent } from './outreach-campaign-request/outreach-campaign-request.component';
import { SubmissionActivityFeedComponent } from './submission-activity-feed/submission-activity-feed.component';
import { SubmissionAttachmentControlsComponent } from './submission-attachment-controls/submission-attachment-controls.component';
import { OutreachApprovalValidationErrorComponent } from './outreach-approval-validation-error/outreach-approval-validation-error.component';
import { SubmissionHistoryComponent } from './submission-history/submission-history.component';
import { ProgramSubmissionComponent } from './program-submission/program-submission.component';
import { ProgramSubmissionAnswersComponent } from './program-submission-answers/program-submission-answers.component';
import { SubmissionHeaderComponent } from './submission-header/submission-header.component';
import { OutreachSubmissionComponent } from './outreach-submission/outreach-submission.component';
import { OutreachSubmissionCommentComponent } from './outreach-submission-comment/outreach-submission-comment.component';
import { InputMoneyComponent } from './input-money/input-money.component';
import { InputPercentComponent } from './input-percent/input-percent.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { InputTextValidatableComponent } from './input-text-validatable/input-text-validatable.component';
import { InputEnumValidatableComponent } from './input-enum-validatable/input-enum-validatable.component';
import { InputTextAreaValidatableComponent } from './input-text-area-validatable/input-text-area-validatable.component';
import { InputDateValidatableComponent } from './input-date-validatable/input-date-validatable.component';
import { InputRadioValidatableComponent } from './input-radio-validatable/input-radio-validatable.component';
import { BudgetSubmissionComponent, BudgetSubmissionAccountDialog } from './budget-submission/budget-submission.component';
import { BudgetSummaryComponent } from './budget-summary/budget-summary.component';
import { CrudComponent, CrudDeleteConfirmationDialog, CrudDialog } from './crud/crud.component';
import { PageControlsComponent } from './page-controls/page-controls.component';
import { PageControlsButtonComponent } from './page-controls-button/page-controls-button.component';
import { DbtitlecasePipe } from './dbtitlecase.pipe';
import { CreateTablePipe } from './create-table.pipe';
import { SubmissionScheduleComponent } from './submission-schedule/submission-schedule.component';
import { InputDropdownButtonsComponent } from './input-dropdown-buttons/input-dropdown-buttons.component';
import { SubmissionScheduleActionDropdownComponent } from './submission-schedule-action-dropdown/submission-schedule-action-dropdown.component';
import { SubmissionMaintenanceWizardComponent } from './submission-maintenance-wizard/submission-maintenance-wizard.component';
import { CrudObservabledComponent, CrudObservabledDeleteConfirmationDialog, CrudObservabledDialog } from './crud-observabled/crud-observabled.component';
import { BudgetPersonnelComponent, BudgetPersonnelDeleteConfirmationDialog } from './budget-personnel/budget-personnel.component';
import { BudgetAppropriationComponent } from './budget-appropriation/budget-appropriation.component';
import { SubmissionMaintenanceWizardConfirmationComponent } from './submission-maintenance-wizard-confirmation/submission-maintenance-wizard-confirmation.component';
import { InputEnumAsyncComponent } from './input-enum-async/input-enum-async.component';
import { UtilityReportComponent } from './utility-report/utility-report.component';
import { MainTargetComponent } from './main-target/main-target.component';
import { Button3Component } from './button3/button3.component';
import { BudgetAgencyCommentComponent } from './budget-agency-comment/budget-agency-comment.component';
import { FilterComponent } from './search/filter/filter.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { CheckboxComponent } from './input/checkbox/checkbox.component';
import { DateComponent } from './input/date/date.component';
import { DocumentComponent } from './input/document/document.component';
import { DropdownButtonsComponent } from './input/dropdown-buttons/dropdown-buttons.component';
import { EnumComponent } from './input/enum/enum.component';
import { ImageComponent } from './input/image/image.component';
import { MoneyComponent } from './input/money/money.component';
import { NumberComponent } from './input/number/number.component';
import { PasswordComponent } from './input/password/password.component';
import { PercentComponent } from './input/percent/percent.component';
import { RadioComponent } from './input/radio/radio.component';
import { SplitButtonComponent } from './input/split-button/split-button.component';
import { ButtonComponent } from './input/button/button.component';
import { TextComponent } from './input/text/text.component';
import { TextAreaComponent } from './input/text-area/text-area.component';
import { TypedComponent } from './input/typed/typed.component';
import { DocumentSubmissionComponent } from './document-submission/document-submission.component';
import { BudgetSummaryDetailComponent } from './budget-summary-detail/budget-summary-detail.component';
import { CrudChildTreeComponent } from './crud-child-tree/crud-child-tree.component';
import { BudgetAccountComponent, BudgetSubmissionDeleteConfirmationDialog } from './budget-account/budget-account.component';
import { CrudChildNodeComponent } from './crud-child-node/crud-child-node.component';
import { SubmissionMaintenanceWizardErrorComponent } from './submission-maintenance-wizard-error/submission-maintenance-wizard-error.component';
import { CrudDdlComponent } from './crud-ddl/crud-ddl.component';
import { Wic22ResultsComponent } from './wic22-results/wic22-results.component';
import { BudgetAccountWrapperComponent } from './budget-account-wrapper/budget-account-wrapper.component';
import { BannerMessageComponent } from './banner-message/banner-message.component';
import { MainTargetEntityComponent } from './main-target-entity/main-target-entity.component';
import { VoucherSummaryComponent } from './voucher-summary/voucher-summary.component';
import { VoucherEditComponent, VoucherSubmissionDeleteConfirmationDialog } from './voucher-edit/voucher-edit.component';
import { VoucherSubmissionAccountDialog, VoucherSubmissionBalanceOffDialog, VoucherSubmissionComponent } from './voucher-submission/voucher-submission.component';
import { VoucherPaymentReportComponent } from './voucher-payment-report/voucher-payment-report.component';
import { AgencyMainTargetComponent } from './agency-main-target/agency-main-target.component';
import { VoucherLinesComponent } from './voucher-lines/voucher-lines.component';
import { AttachmentCommentComponent } from './submission-attachments/attachment-comment.component';
import { VoucherFlagCommentComponent } from './voucher-lines/voucher-flag-comment.component';
import { MainTargetEntityEditComponent, MainTargetEntityEditErrorComponent } from './main-target-entity/main-target-entity-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import {  MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { VoucherLinesAddComponent } from './voucher-lines/voucher-lines-add.component';
import { VoucherLinesEditComponent } from './voucher-lines/voucher-lines-edit.component';
import { BudgetSummaryHeaderComponent } from './budget-summary-header/budget-summary-header.component';
import { VoidSubmissionComponent } from './void-submission/void-submission.component'
import { VoidDialogElements } from './void-submission/void-submission-dialog.component';
import { VoucherReportComponent } from './voucher-report/voucher-report.component';
import { ReportBudgetComponent } from './report-budget/report-budget.component';
import { ConfirmCreateDialogComponent } from './confirm-create-dialog/confirm-create-dialog.component';
import { ConfirmCreateElements } from './confirm-create-dialog/confirm-create-elements.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDeleteElements } from './confirm-delete-dialog/confirm-delete-elements.component';
import { ReportProgramComponent } from './report-program/report-program.component';
import { ProgramDataSelectionsComponent } from './program-data-selections/program-data-selections.component';
import { VoucherOutreachComponent } from './voucher-lines/voucher-outreach-add.component';
import { EmptyVoucherOutreachDialog } from './voucher-lines/voucher-outreach-add.component';
import { PersonnelComponent } from './personnel/personnel.component';
import { PersonnelCalculationWorksheetComponent } from './personnel/personnel-calculation-worksheet/personnel-calculation-worksheet.component';
import { EditBottomFringeDialog, EditTopFringeDialog, PersonnelFringeComponent } from './personnel/personnel-fringe/personnel-fringe.component'
import { AddPersonnelProcedureDialog } from './budget-submission/budget-submission.component';
import { PersonnelTypeDialog, PersonnelDeleteDialog } from './personnel/personnel.component';
import { FlagCommentsComponent } from './flag-comments/flag-comments.component';
import { FlagCommentDialog } from './flag-comments/flag-comment-dialog.component';
import { NumberFormatDirective } from './directives/number-format.directive';
import { ViewAgencyComment } from './personnel/personnel-fringe/personnel-fringe.component';
import { RoundUpPipe } from './pipes/round-up.pipe';
import { RoundUpDirective } from './round-up.directive';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { VoucherSummaryHeaderComponent } from './voucher-summary-header/voucher-summary-header.component';
import { AssignRoleDialog } from './manage-users/assign-role-dialog.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { ManageUsersMatComponent, AddUserOrgSelectDialog, EditUserStatusDialog } from './manage-users-mat/manage-users-mat.component';
import { OutreachBankAccountReportComponent } from './outreach-bank-account-report/outreach-bank-account-report.component';
import { OutreachBankAccountComponent } from './outreach-bank-account/outreach-bank-account.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { TagModule } from 'primeng/tag';

@NgModule({
    declarations: [
        AppComponent,
        NumberFormatPipe,
        MainMenuComponent,
        LoginComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        ForgotPasswordComponent,
        ForgotPasswordResetComponent,
        InputPasswordComponent,
        SystemErrorComponent,
        LogoHeaderComponent,
        DashboardComponent,
        OverdueItemsComponent,
        SearchComponent,
        DocumentSearchComponent,
        OutreachApprovalComponent,
        BudgetAmendmentComponent,
        DocumentForReviewComponent,
        BudgetCalculatorComponent,
        ProgramDataCollectionComponent,
        OverdueTransactionsComponent,
        ManageUsersComponent,
        ManageOrganizationsComponent,
        ManageSiteLocationsComponent,
        HelpCenterComponent,
        FaqsComponent,
        MyProfileComponent,
        AgencyComponent,
        InputDateComponent,
        InputImageComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputEnumComponent,
        UserComponent,
        DocumentMasterListingComponent,
        DocumentMasterComponent,
        InputTextAndEnumComponent,
        SortIndicatorComponent,
        InputDocumentComponent,
        FileSizePipe,
        SubmissionReviewComponent,
        ConfirmationComponent,
        SubmissionAttachmentsComponent,
        ConfirmationReviewComponent,
        ManageQuestionsComponent,
        EditQuestionGroupComponent,
        EditQuestionComponent,
        EditQuestionStepComponent,
        SubmissionQuestionStepsComponent,
        InputSplitButtonComponent,
        OutreachCampaignRequestComponent,
        SubmissionActivityFeedComponent,
        SubmissionAttachmentControlsComponent,
        OutreachApprovalValidationErrorComponent,
        SubmissionHistoryComponent,
        ProgramSubmissionComponent,
        ProgramSubmissionAnswersComponent,
        SubmissionHeaderComponent,
        OutreachSubmissionComponent,
        OutreachSubmissionCommentComponent,
        InputMoneyComponent,
        InputPercentComponent,
        ValidationErrorComponent,
        InputTextValidatableComponent,
        InputEnumValidatableComponent,
        InputTextAreaValidatableComponent,
        InputDateValidatableComponent,
        InputRadioValidatableComponent,
        BudgetSubmissionComponent,
        BudgetSubmissionAccountDialog,
        BudgetSummaryComponent,
        BudgetSubmissionDeleteConfirmationDialog,
        EditQuestionDeleteConfirmationDialog,
        AnswerDialog,
        CrudComponent,
        PageControlsComponent,
        PageControlsButtonComponent,
        DbtitlecasePipe,
        CrudDialog,
        CrudDeleteConfirmationDialog,
        CreateTablePipe,
        SubmissionScheduleComponent,
        InputDropdownButtonsComponent,
        SubmissionScheduleActionDropdownComponent,
        SubmissionMaintenanceWizardComponent,
        CrudObservabledComponent,
        CrudObservabledDialog,
        CrudObservabledDeleteConfirmationDialog,
        BudgetPersonnelComponent,
        BudgetPersonnelDeleteConfirmationDialog,
        BudgetAppropriationComponent,
        SubmissionMaintenanceWizardConfirmationComponent,
        InputEnumAsyncComponent,
        UtilityReportComponent,
        MainTargetComponent,
        Button3Component,
        BudgetAgencyCommentComponent,
        FilterComponent,
        InputNumberComponent,
        CheckboxComponent,
        DateComponent,
        DocumentComponent,
        DropdownButtonsComponent,
        EnumComponent,
        ImageComponent,
        MoneyComponent,
        NumberComponent,
        PasswordComponent,
        PercentComponent,
        RadioComponent,
        SplitButtonComponent,
        ButtonComponent,
        TextComponent,
        TextAreaComponent,
        TypedComponent,
        DocumentSubmissionComponent,
        BudgetSummaryDetailComponent,
        BudgetAccountComponent,
        CrudChildTreeComponent,
        CrudChildNodeComponent,
        SubmissionMaintenanceWizardErrorComponent,
        CrudDdlComponent,
        Wic22ResultsComponent,
        BudgetAccountWrapperComponent,
        BannerMessageComponent,
        MainTargetEntityComponent,
        MainTargetEntityEditComponent,
        MainTargetEntityEditErrorComponent,
        VoucherSummaryComponent,
        VoucherEditComponent,
        VoucherSubmissionComponent,
        VoucherSubmissionAccountDialog,
        VoucherSubmissionDeleteConfirmationDialog,
        VoucherPaymentReportComponent,
        AgencyMainTargetComponent,
        VoucherLinesComponent,
        VoucherLinesAddComponent,
        VoucherLinesEditComponent,
        BudgetSummaryHeaderComponent,
        AttachmentCommentComponent,
        VoucherFlagCommentComponent,
        VoidSubmissionComponent,
        VoidDialogElements,
        VoucherReportComponent,
        ReportBudgetComponent,
        ConfirmCreateDialogComponent,
        ConfirmCreateElements,
        ConfirmDeleteDialogComponent,
        ConfirmDeleteElements,
        ReportProgramComponent,
        ProgramDataSelectionsComponent,
        VoucherOutreachComponent,
        EmptyVoucherOutreachDialog,
        PersonnelComponent,
        PersonnelCalculationWorksheetComponent,
        PersonnelFringeComponent,
        EditTopFringeDialog,
        EditBottomFringeDialog,
        TempDashboardDialog,
        AddPersonnelProcedureDialog,
        PersonnelTypeDialog,
        FlagCommentDialog,
        FlagCommentsComponent,
        PersonnelDeleteDialog,
        NumberFormatDirective,
        ViewAgencyComment,
        RoundUpPipe,
        RoundUpDirective,
        VoucherDetailComponent,
        VoucherSummaryHeaderComponent,
        VoucherSubmissionBalanceOffDialog,
        AssignRoleDialog,
        EmailNotificationsComponent,
        ManageUsersMatComponent,
        AddUserOrgSelectDialog,
        OutreachBankAccountReportComponent,
        OutreachBankAccountComponent,
        EditUserStatusDialog
    ],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TableModule,
        PaginatorModule,
        ButtonModule,
        TagModule,
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        }, TitleCasePipe, NumberFormatPipe, DbtitlecasePipe, CreateTablePipe, DatePipe,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }

