<div *ngIf="initialized" class="container-fluid">
    <div class="row mb-2">
        <div class="col page-title">
            <fa-icon [icon]="faHome"></fa-icon>
            My Dashboard
            <div class="filter-select">
                <!-- <app-input-enum label="Program" [fc]="programFC" [enumValuesNameAndId]="programs" [style]="'none'"
                    [change]="changeProgram.bind(this)">
                </app-input-enum> -->
                <!-- <mat-form-field [floatLabel]="'never'">
                    <mat-label>Fiscal Year</mat-label>
                    <mat-select>
                        <mat-option value="2022">2022</mat-option>
                        <mat-option value="2023">2023</mat-option>
                        <mat-option value="2024">2024</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <div class="fiscal-year-container">
                    <span class="fiscal-year-label">Fiscal Year</span>
                    <mat-select class="custom-select" [(ngModel)]="selectedYear" (selectionChange)="fiscalYearChange()">
                        <mat-option *ngFor="let option of fiscalYearOptions" [value]="option.id">{{ option.name }}</mat-option>
                    </mat-select>
                </div>
                
                <!-- <mat-form-field>
                    <mat-select name="fiscalYear">
                        <mat-option *ngFor="let option of fiscalYearOptions" [value]="option.id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <!-- <app-dropdown-mat-input [selectOptions]="fiscalYearOptions"></app-dropdown-mat-input> -->
            </div>
        </div>
    </div>
    <span *ngIf="this.lists[0].rows.length > 0">
        <div class="row">
            <div class="col">
                <h2 class="table-title push-y-4" data-cy="program_header">
                    <fa-icon [icon]="lists[0].icon"></fa-icon>
                    {{this.lists[0].title}}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col px-0">
                <table class="dashboard">
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of this.lists[0].columns; let index = index" [class]="column.styleClass">
                            <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                                (click)="sortClick(0, column.fieldName)">
                                {{column.display}}
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.lists[0].sortField"
                                [sortIsDescending]="this.lists[0].sortDirection" [columnFieldName]="column.fieldName">
                            </app-sort-indicator>
                        </th>
                    </tr>

                    <tr *ngFor="let item of this.lists[0].rows">
                        <td [style.paddingLeft]="'15px'"
                            *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'">
                            {{item.organization_name}}
                        </td>
                        <!-- <td
                            [style.paddingLeft]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM' ? '15px' : ''">
                            <a [routerLink]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                                && (item.status_id == 'NEW' || item.status_id == 'DRAFT' || item.status_id == 'MORE_INFO')
                                ? '/submission-question-steps'
                                : '/submission-review'" [queryParams]="{ submissionUuid: item.submission_uuid }">
                                {{item.name}}
                            </a>
                        </td> -->
                        <td style="padding-left: 15px;">
                            <a [routerLink]="'/submission-question-steps'" [queryParams]="{ submissionUuid: item.submission_uuid }">
                                {{item.name}}
                            </a>
                        </td>
                        <td class="reference-number">
                            <!-- {{item.reference_number}} -->
                        </td>
                        <td>
                            <span class="status_badge" [ngClass]="['submission_status_' + item.status_id ]">
                                {{item.status_name | uppercase}}
                            </span>
                        </td>
                        <td>
                            {{item.jurisdiction_name}}
                        </td>
                        <td class="date-value">
                            {{item.display_date | date : 'MM/dd/yyyy'}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>

    <span *ngIf="this.lists[1].rows.length > 0">
        <div class="row">
            <div class="col">
                <h2 class="table-title push-y-4" data-cy="document_header" style="display: inline-block;">
                    <fa-icon [icon]="lists[1].icon"></fa-icon>
                    {{this.lists[1].title}}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col px-0">
                <table class="dashboard">
                    <tr style="background-color: lightgrey">
                        <th data-cy='document_fields' *ngFor="let column of this.lists[1].columns; let index = index"
                            [class]="column.styleClass">
                            <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                                (click)="sortClick(1, column.fieldName)">
                                {{column.display}}
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.lists[1].sortField"
                                [sortIsDescending]="this.lists[1].sortDirection" [columnFieldName]="column.fieldName">
                            </app-sort-indicator>
                        </th>
                    </tr>

                    <tr *ngFor="let item of this.lists[1].rows">
                        <td [style.paddingLeft]="'15px'"
                            *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'">
                            {{item.organization_name}}
                        </td>
                        <td
                            [style.paddingLeft]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM' ? '15px' : ''">
                            <a data-cy='document_name'
                                [routerLink]="this.userOrganizationType == 'PRIME' || this.userOrganizationType == 'SYSTEM' ? '/submission-review' : '/document-submission'"
                                [queryParams]="{ submissionUuid: item.submission_uuid }">
                                {{item.name}}
                            </a>
                        </td>
                        <td>
                            <!-- {{item.reference_number}} -->
                        </td>
                        <td>
                            <span class="status_badge" [ngClass]="['submission_status_' + item.status_id ]">
                                {{item.status_name | uppercase}}
                            </span>
                        </td>
                        <td class="date-value">
                            {{item.display_date | date : 'MM/dd/yyyy'}}
                        </td>
                        <td>
                            {{item.updated_by}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>

    <span *ngIf="this.lists[2].rows.length > 0">
        <div class="row">
            <div class="col">
                <h2 class="table-title push-y-4" data-cy="outreach_header">
                    <fa-icon [icon]="lists[2].icon"></fa-icon>
                    {{this.lists[2].title}}
                    <span *ngIf="userOrganizationType == 'SUB'" class="clickable-data" (click)="goToOutreachBudgetDetails()">Outreach Bank Account</span>
                </h2>
            </div>
        </div>
        <!-- When we turn this back on the ngIf should check outreachBudget, 
             <div ngIf="outreachBudget" class="row"> then display the div based on previous logic/criteria 8/17/22 -brock 
        -->
        <div *ngIf="showOutreachBudget == true" class="row"> <!-- reversed the check per HSNY-1859 -BROCK -->
            <div class="col outreach-balance">
                Annual SNAP Budget: {{outreachFederal | currency}}&nbsp;&nbsp;Annual Child Nutrition Budget: {{outreachState | currency }}&nbsp;&nbsp;Estimated Balance: {{estimatedBudget | currency }}&nbsp;&nbsp;Actual Balance: {{outreachBalance | currency}}
            </div>
        </div>
        <div *ngIf="outreachBudget" class="row" style="height: .5em">
        </div>
        <div class="row">
            <div class="col px-0">
                <table class="dashboard">
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of this.lists[2].columns; let index = index" [class]="column.styleClass">
                            <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                                (click)="sortClick(2, column.fieldName)">
                                {{column.display}}
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.lists[2].sortField"
                                [sortIsDescending]="this.lists[2].sortDirection" [columnFieldName]="column.fieldName">
                            </app-sort-indicator>
                        </th>
                    </tr>

                    <tr *ngFor="let item of this.lists[2].rows">
                        <td [style.paddingLeft]="'15px'"
                            *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'">
                            {{item.organization_name}}
                        </td>
                        <td
                            [style.paddingLeft]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM' ? '15px' : ''">
                            <a [routerLink]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                                && (item.status_id == 'NEW' || item.status_id == 'DRAFT' || item.status_id == 'MORE_INFO')
                                ? '/outreach-campaign-request'
                                : '/submission-review'" [queryParams]="{ submissionUuid: item.submission_uuid }">
                                {{item.name}}
                            </a>
                        </td>
                        <td>
                            {{item.reference_number}}
                        </td>
                        <td>
                            {{item.jurisdiction_name}}
                        </td>
                        <td>
                            <span class="status_badge" [ngClass]="['submission_status_' + item.status_id ]">
                                {{item.status_name | uppercase}}
                            </span>
                        </td>
                        <td>
                            {{item.outreach_total | currency}}
                        </td>
                        <td class="date-value">
                            {{item.display_date | date : 'MM/dd/yyyy'}}
                        </td>
                        <td>
                            {{item.agency_date | date : 'MM/dd/yyyy'}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>
    <span *ngIf="this.lists[3].rows.length > 0">
        <div class="row">
            <div class="col">
                <h2 class="table-title push-y-4">
                    <fa-icon [icon]="lists[3].icon"></fa-icon>
                    {{this.lists[3].title}}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col px-0">
                <table class="dashboard">
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of this.lists[3].columns; let index = index" [class]="column.styleClass">
                            <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                                (click)="sortClick(3, column.fieldName)">
                                {{column.display}}
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.lists[3].sortField"
                                [sortIsDescending]="this.lists[3].sortDirection" [columnFieldName]="column.fieldName">
                            </app-sort-indicator>
                        </th>
                    </tr>

                    <tr *ngFor="let item of this.lists[3].rows">
                        <td [style.paddingLeft]="'15px'"
                            *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'">
                            {{item.organization_name}}
                        </td>
                        <td
                            [style.paddingLeft]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM' ? '15px' : ''">
                            <a [routerLink]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                                && (item.status_id == 'NEW' || item.status_id == 'DRAFT' || item.status_id == 'MORE_INFO')
                                ? '/budget-summary'
                                : '/submission-review'" [queryParams]="{ submissionUuid: item.submission_uuid }">
                                {{item.name}}
                                <!-- <span *ngIf="item.sequence && item.sequence != 0">({{item.sequence}})</span> -->
                            </a>
                        </td>
                        <td>
                            <span class="status_badge" [ngClass]="['submission_status_' + item.status_id ]">
                                {{item.status_name | uppercase}}
                            </span>
                        </td>
                        <td>
                            {{item.budget_state | currency}}
                        </td>
                        <td>
                            {{item.budget_federal | currency}}
                        </td>
                        <td>
                            {{item.budget_state + item.budget_federal | currency}}
                        </td>
                        <td class="date-value">
                            {{item.display_date | date : 'MM/dd/yyyy'}}
                        </td>
                        <td>
                            {{item.updated_by}}
                        </td>
                        <td>
                            <app-confirm-create-dialog
                                [amendFlag]="item.amend_flag" 
                                [submissionUuid]="item.submission_uuid"
                                [submissionType]="this.lists[3].title"
                                [status]="item.status_id">
                            </app-confirm-create-dialog>

                            <!-- Moved to confirm create dialog component HSNY-2237 -->
                            <!-- <app-button3 *ngIf="item.amend_flag == 'Y'" label="Create Amendment"
                                [clickFunction]="amendBudget.bind(this, item.submission_uuid)" [icon]="faEdit"
                                [small]="true">
                            </app-button3> -->
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>
    <span *ngIf="this.lists[4].rows.length > 0">
        <div class="row">
            <div class="col">
                <h2 class="table-title push-y-4">
                    <fa-icon [icon]="lists[4].icon"></fa-icon>
                    {{this.lists[4].title}}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col px-0">
                <table class="dashboard">
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of this.lists[4].columns; let index = index" [class]="column.styleClass">
                            <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                                (click)="sortClick(3, column.fieldName)">
                                {{column.display}}
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.lists[4].sortField"
                                [sortIsDescending]="this.lists[4].sortDirection" [columnFieldName]="column.fieldName">
                            </app-sort-indicator>
                        </th>
                    </tr>

                    <tr *ngFor="let item of this.lists[4].rows">
                        <td [style.paddingLeft]="'15px'"
                            *ngIf="userOrganizationType == 'PRIME' || userOrganizationType == 'SYSTEM'">
                            {{item.organization_name}}
                        </td>
                        <td *ngIf="item.status_id != 'NEW';else no_link"
                            [style.paddingLeft]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM' ? '15px' : ''">
                            <a [routerLink]="userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                                && (item.status_id == 'NEW' || item.status_id == 'DRAFT' || item.status_id == 'MORE_INFO')
                                ? '/voucher-summary'
                                : '/submission-review'" [queryParams]="{ submissionUuid: item.submission_uuid, dd: item.display_date, fiscalYearId: item.fiscal_year_id}">
                                {{item.name}}
                                <!--<span *ngIf="item.sequence && item.sequence != 0">({{item.sequence}})</span> HSNY-1699 -->
                            </a>
                        </td>
                        <ng-template #no_link>
                            <td style="padding-left: 15px;">
                                {{item.name}}
                            </td>
                        </ng-template>
                        
                        <td>
                            <span class="status_badge" [ngClass]="['submission_status_' + item.status_id ]">
                                {{item.status_name | uppercase}}
                            </span>
                        </td>
                        <td>
                            {{item.voucher_state | currency}}
                        </td>
                        <td>
                            {{item.voucher_federal | currency}}
                        </td>
                        <td>
                            {{item.voucher_total | currency}}
                        </td>
                        <td class="date-value">
                            {{item.display_date | date : 'MM/dd/yyyy'}} 
                        </td>
                        <td>
                            {{item.updated_by}}
                        </td>
                        <td>
                            <a routerLink="/voucher-payment-report" [queryParams]="{ submissionUuid: item.submission_uuid }"></a>
                        </td>
                        <td>
                            <app-confirm-create-dialog
                                dialogName="voucher"
                                dialogMessage=""
                                [budgetId]="item.budget_id"
                                [sysArea]="item.sys_area"
                                [amendFlag]="item.amend_flag" 
                                [submissionUuid]="item.submission_uuid"
                                [submissionType]="this.lists[4].title"
                                [status]="item.status_id">
                            </app-confirm-create-dialog>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>
</div>