import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverdueItemsComponent } from './overdue-items/overdue-items.component';
import { SearchComponent } from './search/search.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { OutreachApprovalComponent } from './outreach-approval/outreach-approval.component';
import { BudgetAmendmentComponent } from './budget-amendment/budget-amendment.component';
import { DocumentForReviewComponent } from './document-for-review/document-for-review.component';
import { BudgetCalculatorComponent } from './budget-calculator/budget-calculator.component';
import { ProgramDataCollectionComponent } from './program-data-collection/program-data-collection.component';
import { OverdueTransactionsComponent } from './overdue-transactions/overdue-transactions.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageOrganizationsComponent } from './manage-organizations/manage-organizations.component';
import { ManageSiteLocationsComponent } from './manage-site-locations/manage-site-locations.component';
import { MainTargetEntityComponent } from './main-target-entity/main-target-entity.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { FaqsComponent } from './faqs/faqs.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AgencyComponent } from './agency/agency.component';
import { UserComponent } from './user/user.component';
import { DocumentMasterComponent } from './document-master/document-master.component';
import { DocumentMasterListingComponent } from './document-master-listing/document-master-listing.component';
import { SubmissionReviewComponent } from './submission-review/submission-review.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationReviewComponent } from './confirmation-review/confirmation-review.component';
import { ManageQuestionsComponent } from './manage-questions/manage-questions.component';
import { EditQuestionStepComponent } from './edit-question-step/edit-question-step.component';
import { EditQuestionGroupComponent } from './edit-question-group/edit-question-group.component';
import { EditQuestionComponent } from './edit-question/edit-question.component';
import { SubmissionQuestionStepsComponent } from './submission-question-steps/submission-question-steps.component';
import { OutreachCampaignRequestComponent } from './outreach-campaign-request/outreach-campaign-request.component';
import { ProgramSubmissionComponent } from './program-submission/program-submission.component';
import { ProgramSubmissionAnswersComponent } from './program-submission-answers/program-submission-answers.component';
import { BudgetSummaryComponent } from './budget-summary/budget-summary.component';
import { CrudComponent } from './crud/crud.component';
import { SubmissionScheduleComponent } from './submission-schedule/submission-schedule.component';
import { SubmissionMaintenanceWizardComponent } from './submission-maintenance-wizard/submission-maintenance-wizard.component';
import { CrudObservabledComponent } from './crud-observabled/crud-observabled.component';
import { BudgetPersonnelComponent } from './budget-personnel/budget-personnel.component';
import { BudgetAppropriationComponent } from './budget-appropriation/budget-appropriation.component';
import { SubmissionMaintenanceWizardConfirmationComponent } from './submission-maintenance-wizard-confirmation/submission-maintenance-wizard-confirmation.component';
import { UtilityReportComponent } from './utility-report/utility-report.component';
import { MainTargetComponent } from './main-target/main-target.component';
import { DocumentSubmissionComponent } from './document-submission/document-submission.component';
import { CrudChildTreeComponent } from './crud-child-tree/crud-child-tree.component';
import { BudgetSummaryDetailComponent } from './budget-summary-detail/budget-summary-detail.component';
import { SubmissionMaintenanceWizardErrorComponent } from './submission-maintenance-wizard-error/submission-maintenance-wizard-error.component';
import { CrudDdlComponent } from './crud-ddl/crud-ddl.component';
import { Wic22ResultsComponent } from './wic22-results/wic22-results.component';
import { BudgetAccountWrapperComponent } from './budget-account-wrapper/budget-account-wrapper.component';
import { VoucherSummaryComponent } from './voucher-summary/voucher-summary.component';
import { VoucherEditComponent } from './voucher-edit/voucher-edit.component';
import { VoucherPaymentReportComponent } from './voucher-payment-report/voucher-payment-report.component';
import { AgencyMainTargetComponent } from './agency-main-target/agency-main-target.component';
import { VoucherLinesComponent } from './voucher-lines/voucher-lines.component';
import { VoidSubmissionComponent } from './void-submission/void-submission.component';
import { VoucherReportComponent } from './voucher-report/voucher-report.component';
import { ReportBudgetComponent } from './report-budget/report-budget.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { ReportProgramComponent } from './report-program/report-program.component';
import { VoucherOutreachComponent } from './voucher-lines/voucher-outreach-add.component';
import { PersonnelComponent } from './personnel/personnel.component';
import { FlagCommentsComponent } from './flag-comments/flag-comments.component';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { ManageUsersMatComponent } from './manage-users-mat/manage-users-mat.component';
import { OutreachBankAccountReportComponent } from './outreach-bank-account-report/outreach-bank-account-report.component';
import { OutreachBankAccountComponent } from './outreach-bank-account/outreach-bank-account.component';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'terms-of-use', component: TermsOfUseComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ForgotPasswordResetComponent },
    { path: 'set-password', component: ForgotPasswordResetComponent },
    { path: 'system-error', component: SystemErrorComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'overdue-items', component: OverdueItemsComponent, canActivate: [AuthGuard] },
    { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'document-search', component: DocumentSearchComponent, canActivate: [AuthGuard] },
    { path: 'outreach-approval', component: OutreachApprovalComponent, canActivate: [AuthGuard] },
    { path: 'budget-amendment', component: BudgetAmendmentComponent, canActivate: [AuthGuard] },
    { path: 'document-for-review', component: DocumentForReviewComponent, canActivate: [AuthGuard] },
    { path: 'budget-calculator', component: BudgetCalculatorComponent, canActivate: [AuthGuard] },
    { path: 'program-data-collection', component: ProgramDataCollectionComponent, canActivate: [AuthGuard] },
    { path: 'overdue-transactions', component: OverdueTransactionsComponent, canActivate: [AuthGuard] },
    { path: 'manage-users', component: ManageUsersComponent, canActivate: [AuthGuard] },
    { path: 'manage-organizations', component: ManageOrganizationsComponent, canActivate: [AuthGuard] },
    { path: 'manage-site-locations', component: ManageSiteLocationsComponent, canActivate: [AuthGuard] },
    { path: 'main-target-entity', component: MainTargetEntityComponent, canActivate: [AuthGuard] },
    { path: 'help-center', component: HelpCenterComponent, canActivate: [AuthGuard] },
    { path: 'help', component: HelpCenterComponent, canActivate: [AuthGuard] },
    { path: 'faqs', component: FaqsComponent, canActivate: [AuthGuard] },
    { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
    { path: 'agency', component: AgencyComponent, canActivate: [AuthGuard] },
    { path: 'agency-main-target', component: AgencyMainTargetComponent, canActivate: [AuthGuard] },
    { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
    { path: 'document-master-listing', component: DocumentMasterListingComponent, canActivate: [AuthGuard] },
    { path: 'document-master', component: DocumentMasterComponent, canActivate: [AuthGuard] },
    { path: 'document-submission', component: DocumentSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'submission-review', component: SubmissionReviewComponent, canActivate: [AuthGuard] },
    { path: 'confirmation', component: ConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'confirmation-review', component: ConfirmationReviewComponent, canActivate: [AuthGuard] },
    { path: 'manage-questions', component: ManageQuestionsComponent, canActivate: [AuthGuard] },
    { path: 'edit-question-step', component: EditQuestionStepComponent, canActivate: [AuthGuard] },
    { path: 'edit-question-group', component: EditQuestionGroupComponent, canActivate: [AuthGuard] },
    { path: 'edit-question', component: EditQuestionComponent, canActivate: [AuthGuard] },
    { path: 'submission-question-steps', component: SubmissionQuestionStepsComponent, canActivate: [AuthGuard] },
    { path: 'outreach-campaign-request', component: OutreachCampaignRequestComponent, canActivate: [AuthGuard] },
    { path: 'program-submission', component: ProgramSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'program-submission-answers', component: ProgramSubmissionAnswersComponent, canActivate: [AuthGuard] },
    { path: 'budget-summary', component: BudgetSummaryComponent, canActivate: [AuthGuard] },
    { path: 'budget-personnel', component: BudgetPersonnelComponent, canActivate: [AuthGuard] },
    { path: 'crud', component: CrudComponent, canActivate: [AuthGuard] },
    { path: 'crud-observabled', component: CrudObservabledComponent, canActivate: [AuthGuard] },
    { path: 'submission-schedule', component: SubmissionScheduleComponent, canActivate: [AuthGuard] },
    { path: 'submission-maintenance-wizard', component: SubmissionMaintenanceWizardComponent, canActivate: [AuthGuard] },
    { path: 'submission-maintenance-wizard-confirmation', component: SubmissionMaintenanceWizardConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'submission-maintenance-wizard-error', component: SubmissionMaintenanceWizardErrorComponent, canActivate: [AuthGuard] },
    { path: 'budget-appropriation', component: BudgetAppropriationComponent, canActivate: [AuthGuard] },
    { path: 'utility-report', component: UtilityReportComponent, canActivate: [AuthGuard] },
    { path: 'main-target', component: MainTargetComponent, canActivate: [AuthGuard] },
    { path: 'crud-child-tree', component: CrudChildTreeComponent, canActivate: [AuthGuard] },
    { path: 'budget-summary-detail', component: BudgetSummaryDetailComponent, canActivate: [AuthGuard] },
    { path: 'crud-ddl', component: CrudDdlComponent, canActivate: [AuthGuard] },
    { path: 'wic22-results', component: Wic22ResultsComponent, canActivate: [AuthGuard] },
    { path: 'budget-account', component: BudgetAccountWrapperComponent, canActivate: [AuthGuard] },
    { path: 'voucher-summary', component: VoucherSummaryComponent, canActivate: [AuthGuard] },
    { path: 'voucher-edit', component: VoucherEditComponent, canActivate: [AuthGuard] },
    { path: 'voucher-payment-report', component: VoucherPaymentReportComponent, canActivate: [AuthGuard]},
    { path: 'voucher-lines/:accountUuid/:submissionUuid', component: VoucherLinesComponent, canActivate: [AuthGuard]},
    { path: 'void-submission', component: VoidSubmissionComponent, canActivate: [AuthGuard] },
    { path: 'voucher-report', component: VoucherReportComponent, canActivate: [AuthGuard] },
    { path: 'report-budget', component: ReportBudgetComponent, canActivate: [AuthGuard]},
    { path: 'report-program', component: ReportProgramComponent, canActivate: [AuthGuard] },
    { path: 'voucher-outreach', component: VoucherOutreachComponent, canActivate: [AuthGuard] },
    { path: 'personnel', component: PersonnelComponent, canActivate: [AuthGuard] },
    { path: 'voucher-detail', component: VoucherDetailComponent, canActivate: [AuthGuard] },
    { path: 'email-notifications', component: EmailNotificationsComponent, canActivate: [AuthGuard] },
    { path: 'manage-users-mat', component: ManageUsersMatComponent, canActivate: [AuthGuard] },
    { path: 'outreach-bank-account-report' , component: OutreachBankAccountReportComponent, canActivate: [AuthGuard] },
    { path: 'outreach-bank-accounts', component: OutreachBankAccountComponent, canActivate: [AuthGuard] },
    { path: '**', component: LoginComponent }
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }