import { Component, OnInit, Inject, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faBan, faCheck, faFileSignature, faHourglass, faMinus, faMoneyBillWave, faSave, faStamp, faUser, faFlag } from '@fortawesome/free-solid-svg-icons';
// import { MockPersonnelService } from '../services/mock-personnel.service';
import { PersonnelService } from '../services/personnel.service';
import { Personnel } from '../model/personnel.model';
import { iPayPeriod } from '../model/pay-period.model';
import { ActivatedRoute } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from '@angular/material/radio';
import { SessionService } from '../session.service';
import { HelperService } from '../services/helper.service';

interface iTotalAllocation {
  title: string;
  new: number;
  previous: number;
  change: number;
}

@Component({
  selector: 'app-personnel',
  templateUrl: './personnel.component.html',
  styleUrls: ['./personnel.component.css']
})
export class PersonnelComponent implements OnInit 
{
  faUser = faUser;
  faMoneyBillWave = faMoneyBillWave;
  faHourglass = faHourglass;
  faFileSignature = faFileSignature;
  faStamp = faStamp;
  faCheck = faCheck;
  faSave = faSave;
  faBan = faBan;
  faMinus = faMinus;
  faFlag = faFlag;

  selectedButton: string = null
  payRateOrSalaryLabel: string = 'Annual Salary';
  salaryOrPayRateLabel: string = 'Hourly Rate';
  newHourlyRateOrSalaryLabel: string = 'New Salary Amount';
  newSalaryOrHourlyRateLabel: string = 'New Hourly Rate';

  // personnel program type variables
  originalType: string
  personnelType: string

  employeeDetails = new UntypedFormGroup
  ({
    employeeTitle: new UntypedFormControl('', Validators.required),
    employeeName: new UntypedFormControl('', Validators.required),
    // hire date = Start on NOEP
    hireDate: new UntypedFormControl(Validators.required),
    // termination date = End on NOEP
    terminationDate: new UntypedFormControl(),
    personnelType: new UntypedFormControl(Validators.required),
    contractTimeAllocation: new UntypedFormControl(Validators.required),
    estimatedTimeOnContract: new UntypedFormControl(null, Validators.required)
  });

  payrollInfo = new UntypedFormGroup
  ({
    payPeriod: new UntypedFormControl('', Validators.required),
    payRateOrSalary: new UntypedFormControl(0, Validators.required),
    hoursPerDay: new UntypedFormControl(0, Validators.required),
    salaryOrPayRate: new UntypedFormControl(0, Validators.required),
    receivingRaise: new UntypedFormControl(),
    newHourlyRateOrSalary: new UntypedFormControl(0),
    estimatedRaiseDate: new UntypedFormControl(),
    newSalaryAmountOrHourlyRate: new UntypedFormControl(0),
    receivingBonus: new UntypedFormControl(),
    estimatedBonusAmount: new UntypedFormControl(0),
  });

  estimatedHoursPerPayPeriod: number
  annualHoursOnContract: number
  calculatedAmount: number
  // contractInfo = new FormGroup
  // ({
  //   estimatedHoursPerPayPeriod: new FormControl(),
  //   annualHoursOnContract: new FormControl(),
  //   calculatedAmount: new FormControl(),
  // });

  totalAllocation = new UntypedFormGroup
  ({
    amountChargedToContract: new UntypedFormControl(0, Validators.required),
    stateOnly: new UntypedFormControl(0, Validators.required),
    stateFedMatch: new UntypedFormControl(0, Validators.required),
    agencyComment: new UntypedFormControl('')
  });

  userFlag = new UntypedFormControl()

  firstColumnsDisplay: string[] = ['title', 'new'];
  allColumnsDisplay: string[] = ['title', 'new', 'previous', 'change'];
  allocationTableData: iTotalAllocation[]
  payPeriodOptions: iPayPeriod[];
  pageData: Personnel[] = [];
  fiscalStartDate: Date;
  fiscalEndDate: Date;
  sequence: number;

  // form input variables
  annualSalary: number;
  numberOfPaychecks: number;
  hoursPerDay: number;
  newAnnualSalary: number;
  preRaiseDays: number;
  hoursPerPayPeriod: number;
  preRaiseHourlyRate: number;
  postRaiseDays: number;
  postRaiseHourlyRate: number;
  bonus: number;
  estimatedPercentTimeOnContract: number;
  hourlyRateFromSalaryTotal: number;
  newHourlyRateFromSalaryTotal: number;
  preRaiseAmountTotal: number;
  postRaiseAmountTotal: number;
  calculatedAmountTotal: number;
  receiveRaise: boolean;
  receiveBonus: boolean;
  fringeAmount: number;
  amountChargedToContract: number;

  // global uuid variables
  financeUuid: string;
  masterUuid: string;
  submissionUuid: string;
  accountUuid: string;

  // misc variables
  saveError: boolean;
  errorMessage: string;
  dialogText: string;
  organizationType: string;
  salaried: number;
  showFlag: boolean;
  allowNameChange: string;
  beenSaved: number;

  // input variables
  @Input() editable: boolean = true;
  @Input() hideButtons: boolean = false;
  @Input() hideFringe: boolean = false;
  @Input() financeUuidInput: string = null;

  selectButton(button: string) 
  {
    console.log(this.selectButton, button)
    if (button !== this.selectedButton) 
    {
      this.selectedButton = button


      const payRateOrSalaryControl = this.payrollInfo.get('payRateOrSalary');
      const salaryOrPayRateControl = this.payrollInfo.get('salaryOrPayRate');
      const newPayRateOrSalaryControl = this.payrollInfo.get('newHourlyRateOrSalary');
      const newSalaryOrHourlyRateControl = this.payrollInfo.get('newSalaryAmountOrHourlyRate');
  
      if (button === 'salary') 
      {
        this.payRateOrSalaryLabel = 'Annual Salary';
        this.salaryOrPayRateLabel = 'Hourly Rate';
        this.newHourlyRateOrSalaryLabel = 'New Salary Amount';
        this.newSalaryOrHourlyRateLabel = 'New Hourly Rate';
  
        const payRateOrSalaryValue = salaryOrPayRateControl.value;
        const salaryOrPayRateValue = payRateOrSalaryControl.value;
        const newPayRateOrSalaryValue = newSalaryOrHourlyRateControl.value;
        const newSalaryOrHourlyRateValue = newPayRateOrSalaryControl.value;
  
        payRateOrSalaryControl.setValue(payRateOrSalaryValue);
        salaryOrPayRateControl.setValue(salaryOrPayRateValue);
        newPayRateOrSalaryControl.setValue(newPayRateOrSalaryValue);
        newSalaryOrHourlyRateControl.setValue(newSalaryOrHourlyRateValue);
      } 
      if (button === 'hourly') 
      {
        this.payRateOrSalaryLabel = 'Hourly Rate';
        this.salaryOrPayRateLabel = 'Annual Salary';
        this.newHourlyRateOrSalaryLabel = 'New Hourly Rate';
        this.newSalaryOrHourlyRateLabel = 'New Salary Amount';
  
        const payRateOrSalaryValue = salaryOrPayRateControl.value;
        const salaryOrPayRateValue = payRateOrSalaryControl.value;
        const newPayRateOrSalaryValue = newSalaryOrHourlyRateControl.value;
        const newSalaryOrHourlyRateValue = newPayRateOrSalaryControl.value;
  
        payRateOrSalaryControl.setValue(payRateOrSalaryValue);
        salaryOrPayRateControl.setValue(salaryOrPayRateValue);
        newPayRateOrSalaryControl.setValue(newPayRateOrSalaryValue);
        newSalaryOrHourlyRateControl.setValue(newSalaryOrHourlyRateValue);
      }
    }
  }

  cancel()
  {
    this.location.back();
  }
  
  saveAndExit() 
  {
    if(this.employeeDetails.valid && this.payrollInfo.valid && this.totalAllocation.valid)
    {
      this.onSave()
      this.location.back();
    }
    else 
    {
      this.goToError();
    }
  }

  onSave()
  {
    this.employeeDetails.markAllAsTouched();
    this.payrollInfo.markAllAsTouched();
    this.totalAllocation.markAllAsTouched();
    let exclusive = 0
    let raiseExpected = 0
    let bonusExpected = 0
    let firstSave = 1

    let formatHireDate: string
    let formatTermDate: string
    let formatRaiseDate: string

    this.validateDates();
    this.validateTotalAllocation();
    if(this.employeeDetails.valid && this.payrollInfo.valid && this.totalAllocation.valid) {
      if(this.employeeDetails.get('estimatedTimeOnContract').value == 100) {
        exclusive = 1
      } else {
        exclusive = 0
      }
      if(this.payrollInfo.get('receivingRaise').value === true) {
        raiseExpected = 1
      } else {
        raiseExpected = 0
      }

      if(this.payrollInfo.get('receivingBonus').value === true) {
        bonusExpected = 1
      } else {
        bonusExpected = 0
      }

      if(this.employeeDetails.get('hireDate').value != null) {
        formatHireDate = formatDate(this.employeeDetails.get('hireDate').value, 'yyyy-MM-dd', 'en-us')
      }

      if(this.employeeDetails.get('terminationDate').value != null) {
        formatTermDate = formatDate(this.employeeDetails.get('terminationDate').value, 'yyyy-MM-dd', 'en-us')
      }

      if(this.payrollInfo.get('estimatedRaiseDate').value != null) {
        formatRaiseDate = formatDate(this.payrollInfo.get('estimatedRaiseDate').value, 'yyyy-MM-dd', 'en-us')
      }
      
      if(this.selectedButton == 'salary') {
        this.salaried = 1
        this.personnelService.updatePersonnel(
          this.masterUuid,
          this.submissionUuid,
          this.employeeDetails.get('employeeTitle').value,
          this.employeeDetails.get('employeeName').value,
          formatHireDate,
          formatTermDate,
          formatRaiseDate,
          this.employeeDetails.get('estimatedTimeOnContract').value,
          exclusive,
          this.payrollInfo.get('payPeriod').value,
          this.hoursPerPayPeriod,
          raiseExpected,
          bonusExpected,
          this.salaried,
          this.payrollInfo.get('estimatedBonusAmount').value,
          this.payrollInfo.get('payRateOrSalary').value, // changes based on selected button salary/hourly
          this.payrollInfo.get('salaryOrPayRate').value, // changes based on selected button salary/hourly
          this.payrollInfo.get('newHourlyRateOrSalary').value,
          this.payrollInfo.get('newSalaryAmountOrHourlyRate').value,
          this.totalAllocation.get('amountChargedToContract').value,
          this.totalAllocation.get('stateOnly').value,
          this.totalAllocation.get('stateFedMatch').value,
          this.totalAllocation.get('agencyComment').value,
          firstSave,
          this.pageData[0].accountUuid
        ).subscribe(response => {
          if(response.success === true) {
            console.log(response.message)
            this.saveError = response.success
            window.location.reload()
          } else {
            console.log(response.message)
            this.saveError = response.success
            this.errorMessage = response.message
            console.log(this.saveError)
          }
        })
      } else {
        this.salaried = 0
        this.personnelService.updatePersonnel(
          this.masterUuid,
          this.submissionUuid,
          this.employeeDetails.get('employeeTitle').value,
          this.employeeDetails.get('employeeName').value,
          formatHireDate,
          formatTermDate,
          formatRaiseDate,
          this.employeeDetails.get('estimatedTimeOnContract').value,
          exclusive,
          this.payrollInfo.get('payPeriod').value,
          this.hoursPerPayPeriod,
          raiseExpected,
          bonusExpected,
          this.salaried,
          this.payrollInfo.get('estimatedBonusAmount').value,
          this.payrollInfo.get('salaryOrPayRate').value, // changes based on selected button hourly/salary
          this.payrollInfo.get('payRateOrSalary').value, // changes based on selected button hourly/salary
          this.payrollInfo.get('newSalaryAmountOrHourlyRate').value,
          this.payrollInfo.get('newHourlyRateOrSalary').value,
          this.totalAllocation.get('amountChargedToContract').value,
          this.totalAllocation.get('stateOnly').value,
          this.totalAllocation.get('stateFedMatch').value,
          this.totalAllocation.get('agencyComment').value,
          firstSave,
          this.pageData[0].accountUuid
        ).subscribe(response => {
          if(response.success === true) {
            console.log(response.message)
            this.saveError = response.success
            window.location.reload()
          } else {
            console.log(response.message)
            this.saveError = response.success
            this.errorMessage = response.message
            console.log(this.saveError)
          }
        })
      }
    }
    else
    {
      this.goToError();
    }
  }

  goToError()
  {
    if(this.employeeDetails.invalid)
    {
      window.scrollTo({top: 0, behavior: 'auto'})
    }
    else if(this.payrollInfo.invalid)
    {
      window.scrollTo({top: 350, behavior: 'auto'})
    }
    else if(this.totalAllocation.invalid)
    {
      window.scrollTo({top: 500, behavior: 'auto'})
    }
  }

  openDeleteDialog()
  {
    const dialogRef = this.dialog.open(PersonnelDeleteDialog, {
      width: '550px',
      data: { fiscalUuid: this.pageData[0].uuid, masterUuid: this.masterUuid},
    })
    dialogRef.afterClosed().subscribe(_result => {
      console.log(_result)
      })
  }

  isLastRow(row: any): boolean 
  {
    const index = this.allocationTableData.indexOf(row);
    return index === this.allocationTableData.length - 1;
  }

  constructor(
    // private mockPersonnelService: MockPersonnelService,
    private personnelService: PersonnelService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private location: Location,
    private helperService: HelperService
  ) { }

  openDialog()
  {
    const dialogRef = this.dialog.open(PersonnelTypeDialog, {
      width: '550px',
      data: { dialogText: this.dialogText, masterUuid: this.masterUuid},
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(_result => {
      console.log(_result)
      if(_result == 'cancel') {
        console.log(this.originalType, this.personnelType)
        this.employeeDetails.get('personnelType').setValue(this.originalType, {emitEvent: false})
      }

      if(_result == 'changed') {
        this.onSave()
      }
    })
  }

  getPayPeriodOptions()
  {
    this.personnelService.getPayPeriods().subscribe((data) => {
      this.payPeriodOptions = data
    })
  }

  getPageData(uuid: string)
  {
    this.personnelService.getPayPeriods().subscribe((data) => {
      this.payPeriodOptions = data
    // personnel service call
      this.personnelService.getDataByUuid(uuid).subscribe(
        (response) => {
          if (response && response.length > 0) {
            this.pageData = response;
            this.sequence = this.pageData[0].sequence;
            this.accountUuid = this.pageData[0].accountUuid;
            this.allowNameChange = this.pageData[0].allowNameChange;
            this.beenSaved = this.pageData[0].saved;

            if(this.allowNameChange == 'N') {
              this.employeeDetails.get('employeeTitle').disable()
              this.employeeDetails.get('employeeName').disable()
            }

            if(this.beenSaved === 1)
            {
              this.employeeDetails.get('estimatedTimeOnContract').disable();
              this.employeeDetails.get('contractTimeAllocation').disable();
            }
            
            // Continue with the logic dependent on the retrieved data
            this.fillEmployeeDetails();
            this.fillPayrollInfo();
            this.fillTotalAllocation();
            this.getFiscalStartDate();
            this.getFiscalEndDate();
            this.calcHoursPerPayPeriod();
            this.calcAnnualHours();
            this.calcCalculatedAmount();
            this.getTableData();
            this.getWorksheetValues();
          } else {
            this.pageData = null;
            console.log('Something went wrong');
          }
        },
        (error) => {
          console.log(error);
        });
    })
  }

  getFiscalStartDate() 
  {
    const hireDate = this.employeeDetails.get('hireDate').value;
    const startDate = hireDate ? new Date(hireDate) : null;
    const startDateDb = this.pageData?.[0]?.startDate ? new Date(this.pageData[0].startDate) : null;
  
    if (startDate && startDateDb && startDate > startDateDb) {
      this.fiscalStartDate = startDate;
    } else if (startDateDb) {
      this.fiscalStartDate = startDateDb;
    } else {
      console.warn('Unable to determine fiscal start date');
      this.fiscalStartDate = null;
    }
  
    console.log('fiscal start date', this.fiscalStartDate);
  }
  
  getFiscalEndDate() {
    const terminationDateFormControl = this.employeeDetails.get('terminationDate').value;
    const terminationDate = terminationDateFormControl ? new Date(terminationDateFormControl) : null;
    const terminationDateDb = this.pageData?.[0]?.endDate ? new Date(this.pageData[0].endDate) : null;
    
    if (terminationDate && terminationDateDb && terminationDate < terminationDateDb) {
      this.fiscalEndDate = terminationDate;
    } else if (terminationDateDb) {
      this.fiscalEndDate = terminationDateDb;
    } else {
      console.warn('Unable to determine fiscal end date');
      this.fiscalEndDate = null;
    }
  
    console.log('fiscal end date', this.fiscalEndDate);
  }

  fillEmployeeDetails()
  {
    this.employeeDetails.get('employeeTitle').setValue(this.pageData[0].dataFinanceDescription)
    this.employeeDetails.get('employeeName').setValue(this.pageData[0].dataFinanceName)
    if (this.pageData[0].hireDate != null)
    {
      this.employeeDetails.get('hireDate').setValue(new Date(this.pageData[0].hireDate))
    }
    if (this.pageData[0].terminationDate != null)
    {
      this.employeeDetails.get('terminationDate').setValue(new Date(this.pageData[0].terminationDate))
    }
    this.employeeDetails.get('estimatedTimeOnContract').setValue(this.pageData[0].percentTimeOnContract)

    if(this.pageData[0].accountUuid == 'NOEP_PRSNL' || this.pageData[0].accountUuid == 'NOEP_PRSNL_OH') {
      this.employeeDetails.get('personnelType').setValue(this.pageData[0].accountUuid, { emitEvent: false });
      this.originalType = this.employeeDetails.get('personnelType').value;
    }

    this.updateContractTimeAllocation();

  }

  fillPayrollInfo()
  {
    this.payrollInfo.get('payPeriod').setValue(this.pageData[0].payPeriod)
    this.payrollInfo.get('hoursPerDay').setValue(this.getHoursPerDay(this.pageData[0].hoursPerPayPeriod, this.getNumberOfPaychecks(this.pageData[0].payPeriod)))
    this.payrollInfo.get('receivingRaise').setValue(this.pageData[0].raiseExpected)
    if (this.pageData[0].raiseDate != null)
    {
      this.payrollInfo.get('estimatedRaiseDate').setValue(new Date(this.pageData[0].raiseDate))
    }
    this.payrollInfo.get('receivingBonus').setValue(this.pageData[0].bonusExpected)
    this.payrollInfo.get('estimatedBonusAmount').setValue(this.pageData[0].bonusAmount)
    
    if (this.pageData[0].salaried === 1)
    {
      this.selectedButton = 'salary';
      this.payRateOrSalaryLabel = 'Annual Salary';
      this.salaryOrPayRateLabel = 'Hourly Rate';
      this.newHourlyRateOrSalaryLabel = 'New Salary Amount';  
      this.newSalaryOrHourlyRateLabel = 'New Hourly Rate';

      this.payrollInfo.get('payRateOrSalary').setValue(this.pageData[0].annualSalary);
      this.payrollInfo.get('salaryOrPayRate').setValue(this.pageData[0].hourlyRate);
      this.payrollInfo.get('newHourlyRateOrSalary').setValue(this.pageData[0].newAnnualSalary);
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').setValue(this.pageData[0].newHourlyRate);
    }
    if (this.pageData[0].salaried === 0)
    {
      this.selectedButton = 'hourly';
      this.payRateOrSalaryLabel = 'Hourly Rate';
      this.salaryOrPayRateLabel = 'Annual Salary';
      this.newHourlyRateOrSalaryLabel = 'New Hourly Rate';
      this.newSalaryOrHourlyRateLabel = 'New Salary Amount';

      this.payrollInfo.get('payRateOrSalary').setValue(this.pageData[0].hourlyRate);
      this.payrollInfo.get('salaryOrPayRate').setValue(this.pageData[0].annualSalary);
      this.payrollInfo.get('newHourlyRateOrSalary').setValue(this.pageData[0].newHourlyRate);
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').setValue(this.pageData[0].newAnnualSalary)
    }

    this.enableBonusOrNot();
    this.enableRaiseOrNot();
  }

  fillTotalAllocation()
  {
    this.totalAllocation.get('amountChargedToContract').setValue((this.pageData[0].unitPrice * 100) / 100);
    this.totalAllocation.get('stateOnly').setValue((this.pageData[0].debitState * 100) / 100);
    this.totalAllocation.get('stateFedMatch').setValue((this.pageData[0].debitFederal * 100) / 100)
    this.totalAllocation.get('agencyComment').setValue(this.pageData[0].agencyComment || '')
  }

  // utilty functions
  
  getFiscalStart(raiseDate: Date): Date
  {
    if (raiseDate == null || raiseDate == undefined)
    {
      raiseDate = new Date();
    }
      raiseDate = new Date(raiseDate)

      const fiscalStart = new Date(raiseDate.getFullYear(), 6, 1);

      if (raiseDate < fiscalStart)
      {
        fiscalStart.setFullYear(fiscalStart.getFullYear() - 1);
      }
    
      return fiscalStart;
  }

  getWeekdays(startDate: Date, endDate: Date): number
  {
    let currentDate = new Date(startDate);
    let weekdayCount = 0;
    endDate = new Date(endDate)

    while (currentDate <= endDate) // <= used here so the endDate is not excluded from the weekday count ~ brock
    {
      const currentDay = currentDate.getDay();  

      // although it produces the same results alternative logic here
      // currentDay >= 1 && currentDay <= 5 will also count only Monday-Friday
      if (currentDay !== 0 && currentDay !== 6)
      {
        weekdayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return weekdayCount;
  }

  validateTotalAllocation() {
    this.totalAllocation.markAllAsTouched()

    const amountChargedToContract = this.totalAllocation.get('amountChargedToContract').value
    const stateOnly = this.totalAllocation.get('stateOnly').value
    const stateFedMatch = this.totalAllocation.get('stateFedMatch').value
    const calculatedAmount = this.calculatedAmount
    const total = stateOnly + stateFedMatch
    const isValidAmount = amountChargedToContract <= calculatedAmount
    const isValidTotal = amountChargedToContract === total

    this.totalAllocation.get('amountChargedToContract').setErrors(null)
    this.totalAllocation.get('stateOnly').setErrors(null)
    this.totalAllocation.get('stateFedMatch').setErrors(null)
    
    if(!isValidAmount) {
      this.totalAllocation.get('amountChargedToContract').setErrors({invalidAmount: true})
    } else {
      this.totalAllocation.get('amountChargedToContract').setErrors(null)
      if(!isValidTotal) {
        this.totalAllocation.get('amountChargedToContract').setErrors({invalidTotal: true})
        this.totalAllocation.get('stateOnly').setErrors({invalidTotal: true})
        this.totalAllocation.get('stateFedMatch').setErrors({invalidTotal: true})
      } else {
        this.totalAllocation.get('amountChargedToContract').setErrors(null)
        this.totalAllocation.get('stateOnly').setErrors(null)
        this.totalAllocation.get('stateFedMatch').setErrors(null)
      }
    }
  }

  isInvalidTotal(formControl: string): boolean {
    return this.totalAllocation.get(formControl).hasError('invalidTotal');
  }  

  validateDates() {
    const hireDate = new Date(this.employeeDetails.get('hireDate').value);
    const estimatedRaiseDate = new Date(this.payrollInfo.get('estimatedRaiseDate').value);
    const terminationDateValue = this.employeeDetails.get('terminationDate').value;
    const terminationDate = terminationDateValue ? new Date(terminationDateValue) : null;
  
    if (hireDate > estimatedRaiseDate) {
      this.payrollInfo.get('estimatedRaiseDate').setErrors({ invalidDate: true });
    }
  
    if (terminationDate && terminationDate < hireDate) {
      this.employeeDetails.get('terminationDate').setErrors({ invalidDate: true });
    }
  
    if (terminationDate && estimatedRaiseDate > terminationDate) {
      this.payrollInfo.get('estimatedRaiseDate').setErrors({ invalidDate: true });
    }
  }
  

  getPreRaiseHourlyRate(selectedButton: string, payrollInfo: UntypedFormGroup): number 
  {
    if (selectedButton === 'hourly') 
    {
      return payrollInfo.get('payRateOrSalary').value;
    } 
    else if (selectedButton === 'salary') 
    {
      return payrollInfo.get('salaryOrPayRate').value;
    }
    return 0; // Default value if selectedButton is neither 'hourly' nor 'salary'
  }

  getPostRaiseHourlyRate(selectedButton: string, payrollInfo: UntypedFormGroup): number 
  {
    if (selectedButton === 'hourly') 
    {
      return payrollInfo.get('newHourlyRateOrSalary').value;
    } 
    else if (selectedButton === 'salary') 
    {
      return payrollInfo.get('newSalaryAmountOrHourlyRate').value;
    }
    return 0; // Default value if selectedButton is neither 'hourly' nor 'salary'
  }

  determineButtonStatus() 
  {
    let button = this.getSelectedButton();

    if (this.pageData[0].salaried === 1) 
    {
      this.selectButton(button);
    } 
    else if (this.pageData[0].salaried === 0) 
    {
      this.selectButton(button)
    }
  }

  addBonus(totalEarned: number, applyBonus: boolean, bonusAmount: number): number 
  {
    if (applyBonus) 
    {
      totalEarned += bonusAmount;
    }
    return totalEarned;
  }

  pchecks: number
  getNumberOfPaychecks(payPeriod: string): number
  {
    const option = this.payPeriodOptions.find(option => option.id === payPeriod)
    if(option) {
      return option.paychecks
    } else {
      return 0
    }
  }

  getHoursPerDay(hoursPerPayPeriod: number, numberOfPaychecks: number): number
  {
    const hoursPerDay = hoursPerPayPeriod * numberOfPaychecks / 260;
    return hoursPerDay;
  }

  getHoursPerPayPeriod(hoursPerDay: number, numberOfPaychecks): number
  {
    return (hoursPerDay * 260) / numberOfPaychecks
  }

  updateContractTimeAllocation() 
  {
    const contractTimeAllocationControl = this.employeeDetails.get('contractTimeAllocation');
  
    if (this.pageData[0].exclusive === 1) 
    {
      contractTimeAllocationControl.setValue('equalTo100');
      this.employeeDetails.get('estimatedTimeOnContract').disable();
    } 
    else if (this.pageData[0].exclusive === 0) 
    {
      contractTimeAllocationControl.setValue('lessThan100');
      if(this.editable && this.beenSaved === 0) {
        this.employeeDetails.get('estimatedTimeOnContract').enable();
      } else {
        this.employeeDetails.get('estimatedTimeOnContract').disable();
      }
    } 
  }
  
  enableRaiseOrNot()
  {
    const receivingRaise = this.payrollInfo.get('receivingRaise');

    if (this.pageData[0].raiseExpected === 1)
    {
      receivingRaise.setValue(true);
      if(this.editable) {
        this.payrollInfo.get('estimatedRaiseDate').enable();
        this.payrollInfo.get('newHourlyRateOrSalary').enable();
      } else {
        this.payrollInfo.get('estimatedRaiseDate').disable();
        this.payrollInfo.get('newHourlyRateOrSalary').disable();
      }
    }
    else
    {
      receivingRaise.setValue(false);
      this.payrollInfo.get('estimatedRaiseDate').disable();
      this.payrollInfo.get('newHourlyRateOrSalary').disable();
    }
  }

  wipeRaise()
  {
    const isRaise = this.payrollInfo.get('receivingRaise').value;

    if (isRaise === true)
    {
      if (this.pageData[0].raiseDate != null)
      {
        this.payrollInfo.get('estimatedRaiseDate').setValue(new Date(this.pageData[0].raiseDate), { emitEvent: false });
      }
      
      if (this.selectedButton === 'hourly')
      {
        this.payrollInfo.get('newHourlyRateOrSalary').setValue(this.pageData[0].newHourlyRate || 0, { emitEvent: false });
      }
      else
      {
        this.payrollInfo.get('newHourlyRateOrSalary').setValue(this.pageData[0].newAnnualSalary || 0, { emitEvent: false });
      }
    }
    else if (isRaise === false)
    {
      this.payrollInfo.get('estimatedRaiseDate').setValue(null, { emitEvent: false });
      this.payrollInfo.get('newHourlyRateOrSalary').setValue(0, { emitEvent: false });
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').setValue(0, { emitEvent: false });
    }
  }

  enableBonusOrNot() 
  {
    const estimatedBonusAmountControl = this.payrollInfo.get('estimatedBonusAmount');
    const receivingBonusControl = this.payrollInfo.get('receivingBonus');
  
    if (this.pageData[0].bonusExpected === 1 && this.pageData[0].bonusAmount === null) 
    {
      receivingBonusControl.setValue(true);
      if(this.editable) {
        estimatedBonusAmountControl.enable();
      } else {
        estimatedBonusAmountControl.disable();
      }
      estimatedBonusAmountControl.setValue(0);
    }
    else if (this.pageData[0].bonusExpected === 1)
    {
      receivingBonusControl.setValue(true);
      if(this.editable) {
        estimatedBonusAmountControl.enable();
      } else {
        estimatedBonusAmountControl.disable();
      }
    }
    else 
    {
      receivingBonusControl.setValue(false);
      estimatedBonusAmountControl.disable();
    }
  }

  wipeBonus()
  {
    const isBonus = this.payrollInfo.get('receivingBonus').value;

    if (isBonus === true)
    {
      this.payrollInfo.get('estimatedBonusAmount').setValue(this.pageData[0].bonusAmount || 0, { emitEvent: false });
    }
    else if (isBonus === false)
    {
      this.payrollInfo.get('estimatedBonusAmount').setValue(0, { emitEvent: false });
    }
  }
  
  getSelectedButton(): string
  {
    console.log(this.pageData[0].salaried)
    if (this.pageData[0].salaried === 1) 
    {
      return 'salary';
    }
    else if (this.pageData[0].salaried === 0)
    {
      return 'hourly';
    }
  }
  
  // calculations

  calcHoursPerPayPeriod()
  {
    const hoursPerDay = this.payrollInfo.get('hoursPerDay').value;
    const payPeriod = this.payrollInfo.get('payPeriod').value;
    const paychecks = this.getNumberOfPaychecks(payPeriod)

    let finalNumber = this.getHoursPerPayPeriod(hoursPerDay, paychecks)
    finalNumber = Math.round(finalNumber * 100) / 100;
    if(finalNumber !== this.estimatedHoursPerPayPeriod) {
      this.estimatedHoursPerPayPeriod = isNaN(finalNumber) ? 0 : finalNumber
    }
  }

  calcAnnualHours()
  {
    const weekdayCount = this.getWeekdays(this.fiscalStartDate, this.fiscalEndDate);
    const hoursPerDay = this.payrollInfo.get('hoursPerDay').value;
    const calculatedNumber = hoursPerDay * weekdayCount 
    const finalNumber = Math.round(calculatedNumber * 100) / 100;

    if(finalNumber !== this.annualHoursOnContract) 
    {
      this.annualHoursOnContract = finalNumber
    }
  }

  calcSalary() 
  {
    const buttonSelected = this.selectedButton;
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));
  
    let newSalary = 0;
  
    if (buttonSelected === 'hourly') 
    {
      const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);
      const hourlyRate = this.payrollInfo.get('payRateOrSalary').value;
      newSalary = hourlyRate * hoursPerPayPeriod * numberOfPaychecks;
      newSalary = Math.round(newSalary * 100) / 100;
    }

    // Update the new salary form control value
    if (newSalary !== this.payrollInfo.get('salaryOrPayRate').value) 
    {
      this.payrollInfo.get('salaryOrPayRate').setValue(newSalary, { emitEvent: false });
    }  
  }
  
  calcHourlyRate() 
  {
    const buttonSelected = this.selectedButton;
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));
  
    let newHourlyRate = 0;
  
    if (buttonSelected === 'salary') 
    {
      const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);
      const salary = this.payrollInfo.get('payRateOrSalary').value;
      newHourlyRate = salary / (hoursPerPayPeriod * numberOfPaychecks);
      newHourlyRate = Math.round(newHourlyRate * 100) / 100;
    }
  
    // Update the new hourly rate form control value
    if (newHourlyRate !== this.payrollInfo.get('salaryOrPayRate').value) 
    {
      this.payrollInfo.get('salaryOrPayRate').setValue(newHourlyRate, { emitEvent: false });
    }
  }
  
  calcNewSalary() 
  {
    const buttonSelected = this.selectedButton;
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));

    let newSalary = 0;
  
    if (buttonSelected === 'hourly') 
    {
      const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);
      const hourlyRate = this.payrollInfo.get('newHourlyRateOrSalary').value;
      newSalary = hourlyRate * hoursPerPayPeriod * numberOfPaychecks;
      newSalary = Math.round(newSalary * 100) / 100;
    }
  
    // Update the new salary form control value
    if (newSalary !== this.payrollInfo.get('newSalaryAmountOrHourlyRate').value) 
    {
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').setValue(isNaN(newSalary) ? 0 : newSalary, { emitEvent: false });
    }
    
  }
  
  calcNewHourlyRate() 
  {
    const buttonSelected = this.selectedButton;
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));
  
    let newHourlyRate = 0;
  
    if (buttonSelected === 'salary') 
    {
      const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);
      const salary = this.payrollInfo.get('newHourlyRateOrSalary').value;
      newHourlyRate = salary / (hoursPerPayPeriod * numberOfPaychecks);
      newHourlyRate = Math.round(newHourlyRate * 100) / 100;
    }
  
    // Update the new hourly rate form control value
    if (newHourlyRate !== this.payrollInfo.get('newSalaryAmountOrHourlyRate').value) 
    {
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').setValue(newHourlyRate, {emitEvent: false});
    }
  }

  calcPreRaiseEarned(): number
  {
    const estimatedTimeOnContract = this.employeeDetails.get('estimatedTimeOnContract').value / 100;
    const startDate = this.employeeDetails.get('hireDate').value;
    const raiseDate = new Date (this.payrollInfo.get('estimatedRaiseDate').value);
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));
    let weekdayCount = this.getWeekdays(this.fiscalStartDate, raiseDate);
    if (raiseDate.getDay() !== 0 && raiseDate.getDay() !== 6) 
    {
      weekdayCount--;
    }
    const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);

    const hoursPerDay = hoursPerPayPeriod * numberOfPaychecks / 260;

    let preRaiseHourlyRate = this.getPreRaiseHourlyRate(this.selectedButton, this.payrollInfo);
    let preRaiseEarned = 0;

    if( this.selectedButton === 'hourly' && startDate < raiseDate && this.payrollInfo.get('receivingRaise').value === true)
    {
      preRaiseEarned = (preRaiseHourlyRate * hoursPerDay) * weekdayCount;
    }
    else if( this.selectedButton === 'salary' && startDate < raiseDate && this.payrollInfo.get('receivingRaise').value === true)
    {
      preRaiseEarned = (preRaiseHourlyRate * hoursPerDay) * weekdayCount;
    }
    preRaiseEarned = Math.round((preRaiseEarned * estimatedTimeOnContract) * 100) / 100;
    return preRaiseEarned;
  }

  calcPostRaiseEarned(): number
  {
    const estimatedTimeOnContract = this.employeeDetails.get('estimatedTimeOnContract').value / 100
    const raiseDate = new Date (this.payrollInfo.get('estimatedRaiseDate').value);
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value))
    const weekdayCount = this.getWeekdays(raiseDate, this.fiscalEndDate);
    const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);

    const hoursPerDay = hoursPerPayPeriod * numberOfPaychecks / 260;

    let postRaiseHourlyRate = this.getPostRaiseHourlyRate(this.selectedButton, this.payrollInfo);
    let postRaiseEarned = 0;

    if(this.selectedButton === 'hourly' && raiseDate < this.fiscalEndDate && this.payrollInfo.get('receivingRaise').value === true)
    {
      postRaiseEarned = (postRaiseHourlyRate * hoursPerDay) * weekdayCount;
    }
    else if(this.selectedButton === 'salary' && raiseDate < this.fiscalEndDate && this.payrollInfo.get('receivingRaise').value === true)
    {
      postRaiseEarned = (postRaiseHourlyRate * hoursPerDay) * weekdayCount;
    }
    postRaiseEarned = Math.round((postRaiseEarned * estimatedTimeOnContract) * 100) / 100;
    

    return postRaiseEarned;
  }

  // take the salary * timOnContract % if there is no raise or bonus
  // if raise then calc preRaiseEarned and postRaiseEarned and add them
  // if bonus add that ontop of the pre existing amount
  calcCalculatedAmount() 
  {
    const estimatedTimeOnContract = this.employeeDetails.get('estimatedTimeOnContract').value / 100;
    const bonusAmount = parseFloat(this.payrollInfo.get('estimatedBonusAmount').value) || 0;
    const applyRaise = this.payrollInfo.get('receivingRaise').value;
    const applyBonus = this.payrollInfo.get('receivingBonus').value;
    const hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value));
    const weekdayCount = this.getWeekdays(this.fiscalStartDate, this.fiscalEndDate);
    const numberOfPaychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value);
    const hoursPerDay = hoursPerPayPeriod * numberOfPaychecks / 260;
    let totalEarned = 0;

    if(!applyRaise && this.selectedButton == 'hourly')
    {
      totalEarned = ((this.payrollInfo.get('payRateOrSalary').value * hoursPerDay) * weekdayCount) * estimatedTimeOnContract;
    }
    else if(!applyRaise && this.selectedButton == 'salary')
    {
      totalEarned = ((this.payrollInfo.get('salaryOrPayRate').value * hoursPerDay) * weekdayCount) * estimatedTimeOnContract;
    }
    else if(applyRaise)
    {
      const preRaiseEarned = this.calcPreRaiseEarned();
      const postRaiseEarned = this.calcPostRaiseEarned();
      totalEarned = (preRaiseEarned + postRaiseEarned);
    }
    if(applyBonus)
    {
      totalEarned = this.addBonus(totalEarned, applyBonus, bonusAmount);
    }
    totalEarned = Math.ceil(totalEarned);
    this.calculatedAmount = isNaN(totalEarned) ? 0 : totalEarned
  }

  calcHourlyRateFromSalaryTotal(annualSalary: number): number 
  {
    return annualSalary / (this.numberOfPaychecks * this.hoursPerPayPeriod);
  }
  
  calcCalculatedAmountTotal(): number 
  {
    return Math.ceil((this.preRaiseAmountTotal + this.postRaiseAmountTotal));
  }
  
  calcCalculatedAmountTotalWithoutNewAnnualSalary(): number 
  {
    return this.calculatedAmount
  }

  // table functions

  getTableData(): iTotalAllocation[]
  {
    const newStateOnly = parseFloat(this.totalAllocation.get('stateOnly').value);
    const newStateMatch = this.totalAllocation.get('stateFedMatch').value / 2;
    const newFedMatch = this.totalAllocation.get('stateFedMatch').value / 2;
    const newTotalAllocation = newStateMatch + newFedMatch + newStateOnly;
    const previousStateOnly = this.pageData[0].debitStateEst || 0;
    const previousStateMatch = this.pageData[0].debitFederalEst / 2;
    const previousFedMatch = this.pageData[0].debitFederalEst / 2;
    const previousTotalAllocation = +previousStateMatch + +previousFedMatch + +previousStateOnly;
    const changeStateOnly = newStateOnly - previousStateOnly;
    const changeStateMatch = newStateMatch - previousStateMatch;
    const changeFedMatch = newFedMatch - previousFedMatch;
    const changeTotalAllocation = newTotalAllocation - previousTotalAllocation;

    this.allocationTableData = 
    [
      {title: 'State Only', new: newStateOnly, previous: previousStateOnly, change: changeStateOnly},
      {title: 'State Match', new: newStateMatch, previous: previousStateMatch, change: changeStateMatch},
      {title: 'Federal Match', new: newFedMatch, previous: previousFedMatch, change: changeFedMatch},
      {title: 'Total Allocation', new: newTotalAllocation, previous: previousTotalAllocation, change: changeTotalAllocation}
    ]

    return this.allocationTableData
  }

  // worksheet functions
  getWorksheetValues()
  {
    const paychecks = this.getNumberOfPaychecks(this.payrollInfo.get('payPeriod').value)
    this.numberOfPaychecks = paychecks;
    this.hoursPerPayPeriod = this.getHoursPerPayPeriod(this.payrollInfo.get('hoursPerDay').value, this.numberOfPaychecks);
    this.estimatedPercentTimeOnContract = this.employeeDetails.get('estimatedTimeOnContract').value / 100;
    this.hoursPerDay = this.payrollInfo.get('hoursPerDay').value;
    this.hoursPerDay = Math.round(this.hoursPerDay * 100) / 100;
    this.receiveRaise = this.payrollInfo.get('receivingRaise').value;
    this.receiveBonus = this.payrollInfo.get('receivingBonus').value;
    this.fringeAmount = this.pageData[0].fringeAmount;
    this.amountChargedToContract = this.totalAllocation.get('amountChargedToContract').value;
    if (this.selectedButton === 'salary')
    {
      this.annualSalary = this.payrollInfo.get('payRateOrSalary').value;
    }
    else if (this.selectedButton === 'hourly')
    {
      this.annualSalary = this.payrollInfo.get('salaryOrPayRate').value;
    }

    if (this.selectedButton === 'salary' && this.receiveRaise === true)
    {
      let raiseDate = new Date (this.payrollInfo.get('estimatedRaiseDate').value);
      this.newAnnualSalary = this.payrollInfo.get('newHourlyRateOrSalary').value;
      this.preRaiseDays = this.getWeekdays(this.fiscalStartDate, raiseDate);
      if (raiseDate.getDay() !== 0 && raiseDate.getDay() !== 6) 
      {
        this.preRaiseDays--;
      }
      this.postRaiseDays = this.getWeekdays(raiseDate, this.fiscalEndDate);
      this.preRaiseHourlyRate = this.getPreRaiseHourlyRate(this.selectedButton, this.payrollInfo);
      this.postRaiseHourlyRate = this.getPostRaiseHourlyRate(this.selectedButton, this.payrollInfo);
    }
    else if (this.selectedButton === 'hourly' && this.receiveRaise === true)
    {
      let raiseDate = new Date (this.payrollInfo.get('estimatedRaiseDate').value);
      this.newAnnualSalary = this.payrollInfo.get('newSalaryAmountOrHourlyRate').value;
      this.preRaiseDays = this.getWeekdays(this.fiscalStartDate, raiseDate);
      if (raiseDate.getDay() !== 0 && raiseDate.getDay() !== 6) 
      {
        this.preRaiseDays--;
      }
      this.postRaiseDays = this.getWeekdays(raiseDate, this.fiscalEndDate);
      this.preRaiseHourlyRate = this.getPreRaiseHourlyRate(this.selectedButton, this.payrollInfo);
      this.postRaiseHourlyRate = this.getPostRaiseHourlyRate(this.selectedButton, this.payrollInfo);
    }

    if (this.payrollInfo.get('receivingBonus').value === true)
    {
      this.bonus = parseFloat(this.payrollInfo.get('estimatedBonusAmount').value) || 0
    }
    this.getWorksheetTotals();
  }

  getWorksheetTotals() 
  {
    this.hourlyRateFromSalaryTotal = this.calcHourlyRateFromSalaryTotal(this.annualSalary);
    
    if (this.receiveRaise === true) 
    {
      this.newHourlyRateFromSalaryTotal = this.calcHourlyRateFromSalaryTotal(this.newAnnualSalary);
      this.preRaiseAmountTotal = this.calcPreRaiseEarned();
      this.postRaiseAmountTotal = this.calcPostRaiseEarned();
      this.calculatedAmountTotal = this.calcCalculatedAmountTotal();
    } 
    else 
    { 
      this.calculatedAmountTotal = this.calcCalculatedAmountTotalWithoutNewAnnualSalary();
    }
    if (this.receiveBonus === true)
    {
      this.calculatedAmountTotal;
    }
  }

  getPersonnelType(event: MatRadioChange) {
    this.personnelType = event.value
    // console.log(this.originalType, this.personnelType)
  }

  disableInputs() {
    this.payrollInfo.disable({emitEvent: false})
    this.employeeDetails.disable({emitEvent: false})
    // this.contractInfo.disable({emitEvent: false})
    this.totalAllocation.disable({emitEvent: false})
  }

  goBack() {
    history.back()
  }

  status: string
  action: string
  ngOnInit() 
  {
    this.route.queryParams.subscribe((params) => {
      if(params['financeUuid']) {
        this.financeUuid = params['financeUuid'];
      } else {
        if(this.financeUuidInput) {
          console.log(this.financeUuidInput)
        }
        if(this.financeUuidInput != null) {
          this.financeUuid = this.financeUuidInput
        }
      }
      if (params['masterUuid']) {
        this.masterUuid = params['masterUuid'];
      }
      if (params['submissionUuid']) {
        this.submissionUuid = params['submissionUuid'];
      }
      if (params['showFlag']) {
        if(params['showFlag'] == 'true') {
          this.showFlag = true
        } else {
          this.showFlag = false
        }
      }


      if (this.financeUuid && this.submissionUuid) {
        this.getPageData(this.financeUuid);
      }


      this.helperService.getSubmissionStatus(this.submissionUuid).subscribe((data) => {
        this.status = data.response[0].status_id

        this.helperService.getStatus(this.status).subscribe((statusData) => {
          console.log('status helper data', statusData)
          if(statusData.response.length >= 1) {
            if(this.organizationType == 'SUB') {
              // handle sub actions
              this.action = statusData.response[0].sub_action
              if(this.action == 'READ_ONLY') {
                this.disableInputs()
              }
            } else {
              // prime users should never have editable access to this page
              this.disableInputs()
            }
          }
        })
      })

      let claims = this.sessionService.getClaims()
      this.organizationType = claims['organization_type']
      // console.log(this.organizationType) 


      //this.getPageData('fa3f0c78-4aab-4f84-b327-060fca2195c6');
      this.payrollInfo.get('salaryOrPayRate').disable();
      // this.contractInfo.get('estimatedHoursPerPayPeriod').disable()
      // this.contractInfo.get('annualHoursOnContract').disable()
      // this.contractInfo.get('calculatedAmount').disable()
      this.payrollInfo.get('newSalaryAmountOrHourlyRate').disable();

      window.scrollTo({top: 0, behavior: 'auto'})

      this.employeeDetails.valueChanges.subscribe((value) => {
        this.validateDates();
        if(this.selectedButton === 'salary')
        {
          this.calcHourlyRate();
          this.calcNewHourlyRate();
        }
        else if(this.selectedButton === 'hourly')
        {
          this.calcSalary();
          this.calcNewSalary();
        }
        this.getFiscalStartDate();
        this.getFiscalEndDate();
        this.calcAnnualHours();
        this.calcHoursPerPayPeriod();
        this.calcCalculatedAmount();
        this.getWorksheetValues();
      });

      this.payrollInfo.valueChanges.subscribe((value) => {
        this.validateDates();
        if(this.selectedButton === 'salary')
        {
          this.calcHourlyRate();
          this.calcNewHourlyRate();
        }
        else if(this.selectedButton === 'hourly')
        {
          this.calcSalary();
          this.calcNewSalary();
        }
        this.calcAnnualHours();
        this.calcHoursPerPayPeriod();
        this.calcCalculatedAmount();
        this.getWorksheetValues();
      });

      this.totalAllocation.valueChanges.subscribe((value) =>{
        this.validateTotalAllocation();
        this.getWorksheetValues();
        this.getTableData();
      });

      this.employeeDetails.get('contractTimeAllocation').valueChanges.subscribe((value) => {
        const estimatedTimeOnContractControl = this.employeeDetails.get('estimatedTimeOnContract');
        if (value === 'equalTo100') 
        {
          estimatedTimeOnContractControl.setValue('100');
          estimatedTimeOnContractControl.disable();
        } 
        else 
        {
          estimatedTimeOnContractControl.enable();
        }
      });

      this.payrollInfo.get('receivingRaise').valueChanges.subscribe((checked: boolean) => {
        if (checked) 
        {
          this.payrollInfo.get('newHourlyRateOrSalary').enable();
          this.payrollInfo.get('estimatedRaiseDate').enable();
        } 
        else 
        {
          this.payrollInfo.get('newSalaryAmountOrHourlyRate').disable();
          this.payrollInfo.get('newHourlyRateOrSalary').disable();
          this.payrollInfo.get('estimatedRaiseDate').disable();
        }
      });

      this.payrollInfo.get('receivingBonus').valueChanges.subscribe((checked: boolean) =>{
        if(checked)
        {
          this.payrollInfo.get('estimatedBonusAmount').enable();
        }
        else
        {
          this.payrollInfo.get('estimatedBonusAmount').disable();
        }
      });

      this.employeeDetails.get('personnelType').valueChanges.subscribe((value) => {
        this.dialogText = value;
        this.openDialog();
      })

      this.userFlag.valueChanges.subscribe((value) => {
        console.log(this.userFlag.value)
      })

      this.payrollInfo.get('receivingBonus').valueChanges.subscribe((value) => {
        this.wipeBonus();
      })
      this.payrollInfo.get('receivingRaise').valueChanges.subscribe((value) => {
        this.wipeRaise();
      })
    });
  }
}


@Component({
  selector: 'personnel-type-dialog',
  templateUrl: './personnel-type-dialog.component.html',
  styleUrls: ['./personnel.component.css']
})

export class PersonnelTypeDialog implements OnInit
{
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      dialogText: string, 
      masterUuid: string, 
      originalType: string 
    }, 
    private personnelService: PersonnelService,
    private dialog: MatDialogRef<PersonnelTypeDialog>
  ){}

  personnelOrAdmin: string;
  changeError: string

  programTypeChange() 
  {
    this.personnelService.updateProgramType(this.data.masterUuid, this.data.dialogText).subscribe(response => {
      if(response.success === true) {
        console.log(response.result)
        this.dialog.close('changed')
      } else {
        console.log(response.error)
        this.changeError = 'Error changing program type.'
      }
    })
  }

  cancel() {
    this.dialog.close('cancel')
  }

  ngOnInit()
  {
    if(this.data.dialogText === 'NOEP_PRSNL_OH')
    {
      this.personnelOrAdmin = 'Administrative'
    }
    else
    {
      this.personnelOrAdmin = 'Program'
    }
  }
}

@Component({
  selector: 'personnel-delete-dialog',
  templateUrl: './delete-personnel-dialog.component.html',
  styleUrls: ['./personnel.component.css']
})

export class PersonnelDeleteDialog implements OnInit 
{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: 
    {
      fiscalUuid: string,
      masterUuid: string
    },
    private personnelService: PersonnelService,
    private location: Location
  ) {}

  confirmCheck: boolean
  deletePersonnel()
  {
    this.personnelService.deletePersonnel(this.data.fiscalUuid, this.data.masterUuid).subscribe((response => {
      console.log(response)
      if(response.success === true)
      {
        console.log(response.message);
        this.location.back();
      }
      else
      {
        console.log(response.error);
        console.log('oops there was an error')
        //NEED TO IMPLIMENT ERROR LOGIC FOR FAILED SAVES
      }
    }))
  }

  ngOnInit(): void 
  {
    
  }
}
